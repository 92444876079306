import React, {Component} from 'react';
import MaterialIcon from 'material-icons-react';
import {getStringFromTO} from '../../common/utils/i18nUtils';

export default class QuestionPreview extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onEdit(uuid) {
    this.props.onChangeQuestion(uuid);
  }

  onRemove(uuid) {
    this.props.onRemoveQuestion({question: uuid});
  }

  render() {
    const {question, ruleUuid} = this.props;
    const {related, answer} = question;

    const newRelated = related.find(related_uuid => related_uuid === ruleUuid)
      && related.filter(related_uuid => related_uuid !== ruleUuid);

    const newAnswer = answer.find(answer => answer.uuid === ruleUuid)
      && answer.filter(answer => answer.uuid !== ruleUuid);

    const long_answer = getStringFromTO(question.answer.filter(answer =>
      answer.type === 'long_answer')[0].text);

    return (
      <div className="question-preview">
        <div className="question-preview__title">
          {getStringFromTO(question.title)}
          <span className="question-preview__short-answer">{getStringFromTO(question.short_answer)}</span>
        </div>
        <div className="question-preview__answer">{long_answer}</div>
        <div className="question-preview__buttons">
          <div className="question-preview__button" onClick={() => this.onEdit(question.uuid)}>
            <MaterialIcon icon="mode_edit" color="#202020" size="24"/>
          </div>
          <div className="question-preview__button"
            onClick={() => this.onRemove({...question, related: newRelated, answer: newAnswer})}>
            <MaterialIcon icon="clear" color="#202020" size="24"/>
          </div>
        </div>
      </div>
    )
  }
}
