import { actions } from '../consts';

export default (state = [], action) => {
  switch (action.type) {
	  case actions.SET_GAMES:
      const { meta, payload } = action;
      return (meta && meta.table === 'games') 
        ? { ...payload }
        : state;
    default:
      return state;
  }
}
