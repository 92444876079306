import {actions} from '../consts';

const INIT_STATE = 'Rules';

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.SET_TAB:
      return action.payload.tab;
    default:
      return state;
  }
}
