import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames';

export class Icon extends Component {

  constructor() {
    super();
    this.state = {useDefaultIcon: false};
  }

  setIconToDefault = (event) => {
    this.setState({useDefaultIcon: true});
  }

  render() {
    const {src, ...rest} = this.props;
    const {useDefaultIcon} = this.state;
    const iconUrl = (!src || useDefaultIcon) ? '/star.png' : src;

    return (
      <img src={iconUrl} onError={this.setIconToDefault} {...rest} />
    )
  }
}
