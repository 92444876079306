import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from 'material-icons-react';
import Faq from '../../components/Faq';
import Button from '../../components/Button';
import SortableList from '../../components/SortableList';
import Tag from '../../components/Tag';
import {TermInput} from '../../components/Input';
import {getStringFromTO} from '../../common/utils/i18nUtils';
import {initNewFaq} from '../../helpers/entities_helper';
import './styles.scss';
import helpers from '../../helpers';
import {connect} from 'react-redux';
import * as version from '../../actions/chosen_version_action';

class FaqOverview extends Component {
  static propTypes = {
    getVersion: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    onAttempt: PropTypes.func.isRequired,
    removeFromVersion: PropTypes.func.isRequired,
    updateVersion: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {locale} = this.props;
    this.state = {
      tags: [],
      action: undefined,
      newQuestion: initNewFaq({
        title: 'New Question',
        locale,
      }),
    };
  }

  onAddQuestionClick = () => {
    const {locale, updateVersion} = this.props;
    const {newQuestion} = this.state;

    helpers.entity.createQuestion({
      ...newQuestion,
      method: updateVersion,
    });
    this.setState({
      newQuestion: initNewFaq({
        title: 'New Question',
        locale,
      }),
    });
  }

  addTag = (tag) => {
    this.setState({tags: [...this.state.tags, tag]});
  }

  onTagClick = (tag) => {
    let tags = [...this.state.tags];
    const index = tags.indexOf(tag)
    tags.splice(index, 1);
    this.setState({tags: tags});
  }

  removeAll = () => {
    this.setState({tags: []});
  }

  filterEntities = (entities) => {

    function arrayToObject(array) {
      const obj = array.reduce((obj, item) => {
        obj[item.uuid] = item
        return obj
      }, {})
      return obj;
    }

    const array = Object.keys(entities).map(function (ent, i) {
      return entities[ent];
    })

    function containsTag(tags, text) {
      let contains = false;
      tags.forEach(function (tag) {
        if (text.toLowerCase().includes(tag.toLowerCase())) {
          contains = true;
        }
      })
      return contains;
    }

    if (this.state.tags.length) {
      const tags = this.state.tags;

      const filtered = array.filter(function (ent, i) {
        if (getStringFromTO(ent.title)) {
          if (containsTag(tags, getStringFromTO(ent.title))) {
            return true;
          }
        }

        if (getStringFromTO(ent.short_answer)) {
          if (containsTag(tags, getStringFromTO(ent.short_answer))) {
            return true;
          }
        }

        const long_answer = ent.answer.filter(answer =>
          answer.type === 'long_answer')[0].text;

        if (getStringFromTO(long_answer)) {
          if (containsTag(tags, getStringFromTO(long_answer))) {
            return true;
          }
        }
        return false;
      })
      return arrayToObject(filtered);
    }

    return arrayToObject(array);
  }

  filterCollection = (collection, entities) => {
    return collection.filter(function (item, i) {
      if (entities[item]) {
        return true
      }
      return false;
    })
  }


  onEditClick = (url) => {
    this.props.onFaqEditClick(url);
  }

  render() {

    const {
      palette, entities, questions, onAttempt, removeFromVersion,
      updateVersion, match, history
    } = this.props;
    const {processing = {}} = this.props;
    const context = this;
    const filteredEntities = this.filterEntities(entities.questions);

    return (
      <div className="wrap1140 content">
        <div className="faqs">
          <div className="faqs_options">
            <div className="faqs_title"><h3>Frequently Asked Question</h3></div>
            <div className="faqs_tags">
              {
                this.state.tags.map(function (tag, i) {
                  return (
                    <div className={'faqs_tag'} onClick={() => context.onTagClick(tag)} key={i}>
                      <Tag
                        color={'#BBDCE0'}>
                        <MaterialIcon icon="clear" size='tiny' color="#fff"/>
                        {tag}
                      </Tag>
                    </div>
                  )
                })
              }
            </div>
            <div className="faqs_options_buttons">
              <Button onClick={this.onAddQuestionClick}>Add Question</Button>
            </div>
          </div>
          <div className="faqs_filter">
            <TermInput
              filter
              placeholder="Search Term"
              onChange={this.addTag}
              onRemove={this.removeAll}/>
          </div>
          <SortableList
            type={'questions'}
            isDragDisabled={this.state.tags.length > 0}
            component={Faq}
            entities={entities.questions}
            collection={this.filterCollection(questions, filteredEntities)}
            target={entities.questions}
            rule_entities={entities.rules}
            processing={processing}
            palette={palette}
            onEditClick={this.onEditClick}
            onAttempt={onAttempt}
            onDelete={removeFromVersion}
            onUpdate={updateVersion}
            params={match.params}
            history={history}
          />
        </div>
      </div>
    )
  }
}


const mapStateToProps = ({db, ui, assets}) => {
  return {
    modal: ui.modal,
  };
};

export default connect(mapStateToProps,
  {
    getVersion: version.get,
    updateVersion: version.update,
    removeFromVersion: version.remove,
    onAttempt: version.attempt
  }
)(FaqOverview);

