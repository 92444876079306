import React, { Component } from 'react';
import { BarLoader } from 'react-spinners';

export default class Spinner extends Component {
  render() {
    return (
      <div className="spinner">
        <BarLoader color="#0090a1" width={200} />
      </div>
    )
  }
}