import { actions } from '../consts';

export default (state = [], action) => {
  switch (action.type) {
    case actions.SET_PROJECTS:
      const { meta, payload } = action;
      return (meta && meta.table === 'projects')
        ? [...payload]
        : state;
    default:
      return state;
  }
}
