import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {ResizableBox} from 'react-resizable';

import {Input} from '../../../Input';

import {parseIdtoData} from './parser';

const defaultWidth = 250; // pixels;
const minVideoWidth = 250; // pixels;

// restriction imposed by previewer CSS
const maxVideoWidth = 720; // pixels

const placeholderText = 'YouTube Preview';
const placeholderURL = `https://dummyimage.com/${defaultWidth}x${defaultWidth}/cccccc/000000&text=${placeholderText.replace(' ', '+')}`;

// convert "number" input onChange= event to integer number
const eventToInt = event => +(+event.target.value).toFixed();

export default class YouTubeWidget extends Component {
  static propTypes = {
    data: PropTypes.shape({
      aspectRatio: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }).isRequired,
    onHandleContent: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const {data: {aspectRatio, width}} = this.props;
    const initialWidth = width > 0 ? width : defaultWidth;
    const initialHeight = initialWidth * aspectRatio;
    this.state = {
      aspectRatio,
      minConstraints: [initialWidth, initialHeight],
      maxConstraints: [initialWidth, initialHeight],
      size: {
        width: initialWidth,
        height: initialHeight,
      },
      width: initialWidth,
    };
  }

  onChange = (attribute, value) => {
    this.onUpdate({[attribute]: value});
  };

  onIdChange = event => {
    const id = event.target.value;
    const data = parseIdtoData(id); // also matches plain ID
    const idIsInvalid = !data;

    this.onUpdate(idIsInvalid ? {id} : data);
    this.setState({
      idHasChanged: true,
      idIsInvalid
    });
  }

  onLoad = () => {
    const {data: {id: oldId, width: oldWidth}} = this.props;
    const {idHasChanged} = this.state; // load was triggered by ID change
    let {naturalHeight: height, naturalWidth: width} = this._image;
    const aspectRatio = height / width;
    const minVideoHeight = minVideoWidth * aspectRatio;
    const maxVideoHeight = maxVideoWidth * aspectRatio;

    // honor current width
    if (oldWidth > 0) {
      width = oldWidth;
      height = width * aspectRatio;
    }

    // limit maximum image width
    if (width > maxVideoWidth) {
      width = maxVideoWidth;
      height = maxVideoHeight;
    }

    // initialize constraints from loaded image
    this.setState(
      {
        aspectRatio,
        idHasChanged: false,
        minConstraints: [
          minVideoWidth,
          minVideoHeight,
        ],
        maxConstraints: [
          maxVideoWidth,
          maxVideoHeight,
        ],
        size: {
          width,
          height,
        },
        width,
        widthIsInvalid: false,
      },
      () => {
        // handle load of new video like end of resize
        if (idHasChanged) this.onResizeStop();
      }
    )
  };

  onResize = (_, {size}) => this.setState({
    size,
    width: +size.width.toFixed(), // convert to integer number
    widthIsInvalid: false,
  });

  onResizeStop = () => {
    const {aspectRatio, size: {width}} = this.state;
    this.onUpdate({
      aspectRatio,
      width: +width.toFixed(), // convert to integer number
    });
  };

  onUpdate = attrs => {
    const {onHandleContent, data, uuid} = this.props;

    onHandleContent({
      data: {
        ...data,
        ...attrs,
      },
      uuid,
    });
  };

  onWidthChange = event => {
    const {
      aspectRatio,
      minConstraints: [minWidth],
      maxConstraints: [maxWidth],
    } = this.state;
    const width = eventToInt(event);
    const widthIsInvalid = (width < minWidth) || (width > maxWidth);

    this.setState(
      {
        width,
        widthIsInvalid,
        ...(widthIsInvalid
          ? undefined // don't touch size for invalid value
          : {
            size: {
              width,
              height: width * aspectRatio,
            },
          }),
      },
      () => {
        // handle manual update like end of resize
        if (!widthIsInvalid) this.onResizeStop();
      })
  }

  render() {
    const {data: {end = 0, id, start = 0}} = this.props;
    const {idIsInvalid, minConstraints, maxConstraints, size, width, widthIsInvalid} = this.state;

    return (
      <div className="rule-builder__youtube" >
        <ResizableBox
          lockAspectRatio
          minConstraints={minConstraints}
          maxConstraints={maxConstraints}
          onResize={this.onResize}
          onResizeStop={this.onResizeStop}
          resizeHandles={['n', 'nw', 'ne', 'w', 'e', 's', 'sw','se']}
          {...size}
        >
          <Fragment>
            <img
              alt={placeholderText}
              onLoad={this.onLoad}
              ref={ref => this._image = ref}
              src={id && !idIsInvalid
                ? `https://img.youtube.com/vi/${id}/mqdefault.jpg`
                : placeholderURL
              }
              {...size}
            />
            <div className="rule-builder__youtube_inputs">
              <div className="label">Id</div>
              <Input
                className={`input input_filter ${idIsInvalid ? 'input_warning' : ''}`}
                onChange={this.onIdChange}
                placeholder="Paste URL..."
                value={id}
              />
              <div className="label">Width</div>
              <Input
                className={`input input_filter ${widthIsInvalid ? 'input_warning' : ''}`}
                onChange={this.onWidthChange}
                type="number"
                value={width}
              />
              <div className="label">Start time [s]</div>
              <Input
                className="input input_filter"
                onChange={event => this.onChange('start', eventToInt(event))}
                type="number"
                value={start}
              />
              <div className="label">End Time [s]</div>
              <Input
                className="input input_filter"
                onChange={event => this.onChange('end', eventToInt(event))}
                type="number"
                value={end}
              />
            </div>
          </Fragment>
        </ResizableBox>
      </div>
    );
  }
}
