import { actions } from '../consts';

const remove = ({ dispatch, getState }) => next => action => {

  if (action.type === actions.ATTEMPT) {
    // Calculate content here and push to modal render
    dispatch({ 
      type: actions.SHOW_MODAL,
      payload: { ...action.payload }
    });
    return next(action);
  }

  if (action.type === actions.CONFIRM) {

    const { ui } = getState();
    action.callback({
      type: ui.modal.type,
      uuid: ui.modal.uuid,
      modules: ui.modal.modules
    });
    //dispatch({ type: actions.FILTER_ALL })
    return next(action);
  }

  return next(action);
};

export default remove;
