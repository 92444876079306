import AssetDataActions from '../../actions/AssetDataActions';

export default (session, action) => {
  const {AssetData} = session;
  switch (action.type) {
    case AssetDataActions.CLEAR_ASSET_DATA.FULFILLED:
      AssetData.all().delete();
      break;
    case AssetDataActions.FETCH_ASSET_DATA_BY_FOLDER.FULFILLED:
      action.payload.data.forEach(asset => {
        const {assetData, locale} = asset;
        // find the assetData for the default asset locale or fall back to first entry
        AssetData.create(assetData.find(ad => ad.locale === locale) || assetData[0]);
      });
      break;
  }
  return session;
};
