import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '../../components/Button';
import * as version from '../../actions/chosen_version_action';
import './styles.scss';
import {getStringFromTO} from '../../common/utils/i18nUtils';
import {Input, TextArea} from '../../components/Input';
import isEmpty from 'lodash/isEmpty';
import Spinner from '../../components/Spinner';
import {createNewPageObject} from '../../helpers/entities_helper';
import {RelatedRules} from '../../components/RelatedRules';
import {limits} from '../../consts';

function onPreventUnload(event) {
  event.preventDefault();
  event.returnValue = '';
}

class FaqEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {...this.props.entities.questions[this.props.match.params.faq]};

    this.preventCloseAndReload();
  }

  onApply = () => {
    if (getStringFromTO(this.state.title)) {
      this.props.updateVersionContent({
        question: {
          uuid: this.props.match.params.faq,
          ...this.state,
          color: this.getFaqColor(this.state.related)
        }
      });
    }
    this.closeEditor();
  }

  preventCloseAndReload() {
    window.addEventListener('beforeunload', onPreventUnload);
  }

  allowCloseAndReload() {
    window.removeEventListener('beforeunload', onPreventUnload);
  }

  closeEditor = () => {
    this.allowCloseAndReload();
    this.props.history.push(`/${this.props.match.params.orgId}/version/${this.props.match.params.version}`);
  }

  onChange = (event) => {
    const {value, name} = event.target;

    if (name === 'answer') {

      const index = this.state.answer.findIndex(answer => answer.type === 'long_answer');
      const answer = [...this.state.answer];

      answer[index] = {
        ...answer[index],
        text: {
          ...answer[index].text,
          text: value
        }
      };
      this.setState({answer: answer})
    } else {
      this.setState({
        [name]: {
          ...this.state[name],
          text: value,
          context: {
            ...this.state[name].context
          }
        }
      });
    }
  }

  updateRelated = (event, uuid) => {
    const related = [...this.state.related]
    const answer = [...this.state.answer]

    const newRelated = related.find(rrUuid => rrUuid === uuid)
      ? related.filter(rrUuid => rrUuid !== uuid)
      : related.concat([uuid]);

    const newAnswer = answer.find(answer => answer.uuid === uuid)
      ? answer.filter(answer => answer.uuid !== uuid)
      : answer.concat(createNewPageObject(uuid));

    this.setState({
      related: newRelated,
      answer: newAnswer,
      color: this.getFaqColor(newRelated)
    });
  }

  getFaqColor = (related) => {
    const rules = this.props.entities.rules;

    if (related[0] && rules[related[0]]) {
      return rules[related[0]].color;
    }

    return null;
  }

  getStyleColor = () => {
    return this.state.color && this.props.palette[this.state.color]
  }

  renderAnswer = () => {
    const {entities, palette} = this.props;
    const longAnswer = getStringFromTO(this.state.answer.filter(answer =>
      answer.type === 'long_answer')[0].text);

    return (
      <div className="faq-editor-body">
        <div className="faq-editor__title">Long Answer</div>
        <TextArea className="faq-editor-answer"
          value={longAnswer}
          name="answer"
          placeholder='Long Answer...'
          onChange={this.onChange}/>
        <div className="faq-editor-related">
          <div className="faq-editor__title">Related Rules</div>
          {this.state.related &&
          <RelatedRules
            parentType={'question'}
            rules={entities.rules}
            details={this.state}
            modules={entities.modules}
            palette={palette}
            onUpdate={this.updateRelated}/>}
        </div>
      </div>
    )
  }

  renderQuestion = () => {
    return (
      <div className="faq-editor-content">
        <div className="faq-editor__grid">
          <div className="faq-editor__title">Question</div>
          <div className="faq-editor__title">Short Answer</div>
          <Input
            className="faq-editor-input-question"
            filter
            maxLength={limits.MAX_FAQ_LENGTH}
            placeholder="Question..."
            name="title"
            value={getStringFromTO(this.state.title)}
            onChange={this.onChange}/>
          <Input
            filter
            className='faq-editor-input-short'
            placeholder="Short Answer..."
            maxLength={limits.MAX_FAQ_SHORT_LENGTH}
            name="short_answer"
            value={getStringFromTO(this.state.short_answer)}
            onChange={this.onChange}/>
        </div>
      </div>
    )
  }

  renderMenu = () => {
    return (
      <div className="faq-editor-menu-items">
        {/*TODO should check if we need this at a later design point*/}
        <div className="faq-editor-menu-header">{/*parent Category*/}</div>
        <div className="faq-editor-menu-buttons">
          <Button onClick={this.closeEditor}>Cancel</Button>
          <Button secondary onClick={this.onApply}>Apply</Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="faq-editor-bg" style={{background: this.getStyleColor()}}>
        <div className="faq-editor">
          {this.renderMenu()}
          {this.renderQuestion()}
          {this.renderAnswer()}
        </div>
      </div>
    )
  }
}

class FaqEditorContainer extends Component {
  componentDidMount() {
    const {details} = this.props,
      {params} = this.props.match,
      {getVersionContent} = this.props;
    if (isEmpty(details)) getVersionContent({id: params.version});
  }

  render() {
    const {fetching, entities, match} = this.props;
    const question = entities.questions[match.params.faq];

    return fetching ? <Spinner/> : !isEmpty(entities.questions) && question
      ? <FaqEditor {...this.props} /> : 'Question doesn\'t exist.'
  }
}

const mapStateToProps = ({db, ui, orm}) => {
  const {entities, details, menus, rules, palette, questions} = db.version;
  return {
    processing: ui.processing,
    fetching: ui.fetching,
    assets: orm.AssetData,
    organization: db.organization,
    entities,
    details,
    menus,
    rules,
    questions,
    palette,
  };
};

export default connect(
  mapStateToProps,
  {
    getVersionContent: version.get,
    updateVersionContent: version.update,
    removeVersionContent: version.remove,
    onCancel: version.cancel
  }
)(FaqEditorContainer);
