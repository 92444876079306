import React, { Component } from 'react';
import classNames from 'classnames';
import Tag from '../Tag';
import RuleHTMLPreviewer from '../RuleHTMLPreviewer';
import {getStringFromTO} from '../../common/utils/i18nUtils';

function previewClassNames(isOpen) {
  return classNames({
    'rule-previewer': true,
    'rule-previewer_show': isOpen,
  });
}

export default class RulePreview extends Component {
  render() {
    const { open, data, entities, palette, questions_entities } = this.props;
    return (
      <div className={previewClassNames(open)}>
        <div className="rule-previewer__html">
          <RuleHTMLPreviewer html={data.html}/>
        </div>
        <div className="rule-previewer__questions">
          <div className="rule-previewer__subheader">Questions</div>
          {Object.values(questions_entities)
            .filter(question => question.related.find(rule => rule === data.uuid))
            .map((question, i) =>
              <div className="rule-previewer__question" key={question.uuid}>
                <span className="rule-previewer__question-number">{i + 1}</span>
                <span className="rule-previewer__title">{getStringFromTO(question.title)}</span>
                <span className="rule-previewer__answer">{getStringFromTO(question.short_answer)}</span>
                <div className="rule-previewer__text">{getStringFromTO(question.answer.filter(answer =>
                  answer.type === 'long_answer')[0].text)}</div>
              </div>)}
        </div>
        <div className="rule-previewer__related">
          <div className="rule-previewer__subheader">Related Rules</div>
          {data.related.map(rule =>
            entities[rule]
              ? <div className="rule-previewer__related-rule" key={entities[rule].uuid}>
                <Tag color={palette[entities[rule].color] || entities[rule].color}>{getStringFromTO(entities[rule].title)}</Tag>
              </div>
              : <div>Rule doesn't exist. Inform us if error occurs.</div>)}
        </div>
      </div>
    );
  }
}
