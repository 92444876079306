import {actions} from '../consts';

export const get = (params) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'get',
    success: set,
    url: `/api/v1/ggl/games${gameFilter(params)}`,
  }
});

export const getSingleGame = (params) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'get',
    success: set,
    url: `/api/v1/ggl/games${gameFilterByGameId(params)}`
  }
});

export const set = (payload) => ({
  type: actions.SET_GAMES,
  payload,
  meta: {
    table: 'games'
  }
});

function gameFilter(params) {
  const filter = {where: {'organizationId': params.orgId}};
  return '?filter=' + JSON.stringify(filter);
}

function gameFilterByGameId(params) {
  const filter = {where: {'uuid': params.gameId}};
  return '?filter=' + JSON.stringify(filter);
}

