import {denormalize, normalize} from 'normalizr';
import {actions} from '../consts';
import {chosen_version_schema} from '../schemas';
import {get as getVersionsInfo} from './version_action';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

// Parsing schema
const version_schema = chosen_version_schema();

// If success -> middleware will invoke success callback with payload
export const get = (params = {}) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'get',
    success: setFromXhr,
    url: `/api/v1/rules/versions/${params.id}`,
  }
});

export const put = (params) => {
  const {entities, ...result} = params;

  // BAADD!! TEMPORARY FIX
  const data = denormalize(result, version_schema, entities);
  const rules = !data.rules.length ? [{}] : data.rules.map(rule => ({...rule, parents: Object.values(rule.parents)}));
  const menus = !data.menus.length ? [{}] : data.menus.map(menu => ({...menu}));
  const questions = !data.questions.length ? [{}] : data.questions;
  return {
    type: actions.API_MIDDLEWARE,
    payload: {
      method: 'put',
      data: migrateAssets({...data, rules, menus, questions}),
      success: (data) => getVersionsInfo({id: data.projectUuid}),
      url: `/api/v1/rules/versions/${data.uuid}`,
    }
  }
};

// BAAAD!!!!
export const setFromXhr = (incoming_data = {}) => {

  // TEMPORARY FIX
  if (isEmpty(incoming_data.rules[0])) {
    incoming_data.rules = [];
  }
  if (isEmpty(incoming_data.menus[0])) {
    incoming_data.menus = [];
  }
  if (isEmpty(incoming_data.questions[0])) {
    incoming_data.questions = [];
  }

  /* TODO migrating old answer json structure to the new 14th october 2018, pre-kickstarter*/
  incoming_data.questions = incoming_data.questions.map(function (question) {
    if (!question.related) {
      question = {...question, related: []}
      if (question.rules) {
        question.related = question.rules;
      }
    }
    if (!Array.isArray(question.answer)) {
      question.answer = [{
        type: 'long_answer',
        text: {...question.answer}
      }]
    }
    return question;
  });

  const normalized_data = normalize(incoming_data, version_schema) || {};
  const {result = {}, entities = {}} = normalized_data;
  const {menus, rules, questions, palette, ...details} = result;

  return {
    type: actions.SET_VERSION,
    payload: {
      menus,
      rules,
      questions,
      palette,
      details,
      entities
    }
  }
};

/**
 * Update the Redux Version Store
 * @param {Object} action - Redux Action object
 * @param {string} [action.type] - Optional action type
 * @param {Object} action.payload - The payload containing the data to update
 * @returns {Object} The Redux action to dispatch
 *
 * Format of the parameter could be of the following configurations:
 *
 * For a menu item entity:
 * ```
 * {
 *   menu: {
 *     uuid: categoryUuid,
 *     color: color
 *   }
 * }
 * ```
 *
 * For a rule item entity:
 * ```
 * {
 *   rule: {
 *     uuid: categoryUuid,
 *     color: color
 *   }
 * }
 * ```
 * You can also include an optional `type` parameter to use an alternative action from `UPDATE_VERSION`.
 * To view all of the action types, see `action-type.js`
 */
export const update = ({type, ...payload}) => {
  // Update the "lastEdited" date of the version:
  //
  // This is used to help prevent navigation when
  // data has changed. It is not saved as part of
  // the version data, it only exists in memory
  // once a change has occurred.
  payload.details = {
    ...(payload.details),
    lastEdited: moment().format(),
  };

  return {
    type: type || actions.UPDATE_VERSION,
    payload
  };
};

export const remove = (payload) => ({
  type: actions.REMOVE_FROM_VERSION,
  payload
});

export const filter = ({type, payload}) => ({
  type,
  payload
});

export const attempt = (payload) => ({
  type: actions.ATTEMPT,
  payload
});

export const reset = () => ({
  type: actions.RESET_CONTENT
});


export const confirm = (callback) => ({type: actions.CONFIRM, callback});
export const cancel = () => ({type: actions.CANCEL});


// Add _type = ao1 to all objects with assetId and locale
function migrateAssets(payload) {
  const data = JSON.stringify(payload, (obj, value) => {
    if (value && value.constructor.name === 'Object' && value.assetId && value.locale && !value._type) {
      value._type = 'ao1';
    }
    return value;
  });
  return JSON.parse(data);
}
