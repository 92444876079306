import propTypes from 'prop-types';
import React, {Component} from 'react';
import helpers from '../../helpers';

export default class InfoList extends Component {
  static propTypes = {
	  version: propTypes.string,
	  updated: propTypes.string,
	  status: propTypes.string,
	  locale: propTypes.array
  }

  renderStatus(status) {
	  if (status) {
	    return (
	      <li className="info-string__li">
					Status: {status}
	      </li>
	    );
	  }

	  return null;
  }

  renderLanguage(locale) {
	  if (locale) {
	    return (
	      <li className="info-string__li">
					Language: {helpers.generic.parseLang(locale)}
	      </li>
	    );
	  }

	  return null;
  }

  render() {
	  const {updated, status, locale} = this.props;
	  return (
	    <ul className="info-string__list">
	      <li className="info-string__li">
					Updated: {helpers.generic.parseFriendlyTime(updated)}
	      </li>
	      {this.renderStatus(status)}
	      {this.renderLanguage(locale)}
	    </ul>
	  )
  }
}
