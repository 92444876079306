import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import MaterialIcon from 'material-icons-react';
import { TYPE, placeholder } from '../../consts';
import { createTO } from '../../../../common/utils/i18nUtils';
import { defaultLocale } from '../../../../helpers/LocaleList';

const getTypeData = (type, locale) => {
  switch (type) {
    case TYPE.TEXT:
    case TYPE.NOTE:
      return {
        type, data: createTO('', locale, {
          application: 'rules-ct',
          description: 'rule description'
        })
      };
    case TYPE.IMAGE:
      return { type, src: placeholder.img, alt: placeholder.text };
    case TYPE.TEXT_IMAGE:
    case TYPE.IMAGE_TEXT:
      return {
        type, src: placeholder.img, alt: placeholder.text, data: createTO('', locale, {
          application: 'rules-ct',
          description: 'rule description'
        })
      };
    case TYPE.YOUTUBE:
      return {
        type,
        data: {
          aspectRatio: 1,
          id: '',
          width: 0,
        },
      };
    default:
      return {};
  }
};

export const Widget = (props) => {
  const { icon, name } = props;

  return (
    <div className={widgetClassNames(props)} >
      {!icon ? name : <MaterialIcon icon={icon} color="#0090a1" size="32" />}
    </div>
  );
};

Widget.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string,
};

function widgetClassNames(props) {
  return classNames({
    widget: true,
    widget_reversed: props.isReversed
  });
}

export const createWidgetModule = (type, locale = defaultLocale) => ({
  uuid: uuidv4(),
  type: 'module',
  data: getTypeData(type, locale),
});
