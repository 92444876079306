import { actions } from '../consts';
import merge from 'lodash/merge';
import moment from 'moment';

export const details_reducer = (state = {}, action) => {
  const { details } = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      return details ? details : state;
    case actions.ADD_TO_VERSION:
    case actions.REMOVE_FROM_VERSION:
      // Update the "lastEdited" date of the version, which is not saved
      return merge({}, state, {lastEdited: moment().format()});
    case actions.UPDATE_VERSION:
      return details
        ? merge({}, state, details)
        : state;
    case actions.RESET_CONTENT:
      return {};
    default:
      return state;
  }
}

export const menus_reducer = (state=[], action) => {
  const { menus, menu, type, uuid } = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      return menus ? menus : state;
    case actions.ADD_TO_VERSION:
      return menu && !state.find(uuid => uuid === menu.uuid)
        ? [...state, menu.uuid]
        : state;
    case actions.UPDATE_VERSION:
      return menus ? [...menus] : state;
    case actions.REMOVE_FROM_VERSION:
      return type === 'menu' ? state.filter(menu => menu !== uuid) : state;
    case actions.RESET_CONTENT:
      return [];
    default:
      return state;
  }
}

export const rules_reducer = (state=[], action) => {
  const { rules, rule, type, uuid } = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      return rules ? rules : state;
    case actions.ADD_TO_VERSION:
      return rule && !state.find(uuid => uuid === rule.uuid)
        ? [...state, rule.uuid]
        : state;
    case actions.UPDATE_VERSION:
      return rules ? [...rules] : state;
    case actions.REMOVE_FROM_VERSION:
      return type === 'rule' ? state.filter(rule => rule !== uuid) : state;
    case actions.RESET_CONTENT:
      return [];
    default:
      return state;
  }
}

export const questions_reducer = (state=[], action) => {
  const { questions, question, type } = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      return questions ? questions : state;
    case actions.ADD_TO_VERSION:
      return question && !state.find(uuid => uuid === question.uuid)
        ? [question.uuid, ...state]
        : state;
    case actions.UPDATE_VERSION:
      if (questions && Array.isArray(questions)) {
        return [...questions];
      } else if (questions && !Array.isArray(questions)) {
        return Object.values(questions).map(question => question.uuid);
      }
      return state;

    case actions.REMOVE_FROM_VERSION:
      return question
        ? state.filter(uuid => uuid !== question)
        : type === 'question'
          ? state.filter(uuid => uuid !== action.payload.uuid)
          : state;
    case actions.RESET_CONTENT:
      return [];
    default:
      return state;
  }
}

export const palette_reducer = (state=[], action) => {
  switch (action.type) {
    case actions.SET_VERSION:
      const { palette } = action.payload;
      return palette ? palette : state;
    case actions.RESET_CONTENT:
      return [];
    default:
      return state;
  }
}
