import PropTypes from 'prop-types';
import React, {Component} from 'react';
import classNames from 'classnames';
import MaterialIcon from 'material-icons-react';
import TextareaAutosize from 'react-textarea-autosize';

export class Input extends Component {

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if (this.props.onEnterPressed) {
        this.props.onEnterPressed(event);
      }
    }
  }

  handleKeyUp = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();

      if (this.props.onEscapePressed) {
        this.props.onEscapePressed(event);
      }
    }
  }

  render() {
    const {filter, onEnterPressed, onEscapePressed, ...rest} = this.props;

    return (
      <input className={inputClassNames(this.props, this.state)}
        onKeyUp={this.handleKeyUp} onKeyPress={this.handleKeyPress}
        {...rest} />
    )
  }
}

export class TermInput extends Component {

  static defaultProps = {
    initValue: ''
  }

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    maxLength: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initValue
    }
  }

  onInput = (e) => {
    if (!e.target.value.includes(' ')) {
      this.setState({value: e.target.value})
    }
  }

  onBlur = (e) => {
    e.target.value !== '' && this.props.onChange(e.target.value);
    this.setState({value: ''});
  }

  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.onBlur(e);
    }
  }

  onChange = () => {

  }

  render() {
    return (
      <React.Fragment>
        <input className={'input_term'}
          value={this.state.value}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder && this.props.placeholder}
          onKeyDown={this.keyPress}
          onInput={this.onInput}
          onChange={this.onChange} />
        <button className={'button input_term_button'} onClick={this.props.onRemove}>
          <MaterialIcon icon="clear" size={30} color="#fff" />
        </button>
      </React.Fragment>
    )
  }
}

export class TextArea extends Component {

  static propTypes = {
    onEnterPressed: PropTypes.func,
    onEscapePressed: PropTypes.func
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if (this.props.onEnterPressed) {
        this.props.onEnterPressed(event);
      }
    }
  }

  handleKeyUp = (event) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      if (this.props.onEscapePressed) {
        this.props.onEscapePressed(event);
      }
    }
  }

  render() {
    const {filter, onEnterPressed, onEscapePressed, ...rest} = this.props;

    return (
      <textarea
        className={inputClassNames(this.props, this.state)}
        onKeyUp={this.handleKeyUp} onKeyPress={this.handleKeyPress}
        {...rest} />
    )
  }
}

export class SmartInput extends Component {

  static defaultProps = {
    initValue: ''
  }

  static propTypes = {
    field: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      [this.props.field]: this.props.initValue
    }
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  onChange(event) {
    // React controls the input
    const {value} = event.target;
    const {field} = this.props;
    this.setState({[field]: value});
  }

  onBlur() {
    // When fire => trigger callback which will update store
    const {field, value, table} = this.props;
    if (this.state[field] === value) return;
    this.props.onBlur({[table]: this.state});
  }

  render() {
    const value = this.state[this.props.field];
    const {initValue, ...rest} = this.props;

    return (
      <TextareaAutosize
        {...rest}
        value={value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        className={
          `${inputClassNames(this.props, this.state)} input_auto-size`
        }
      />
    )
  }
}

export class AutoSizeInput extends Component {

  static defaultProps = {
    initValue: ''
  }

  static propTypes = {
    onBlur: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initValue
    }
  }

  onChange = (event) => {
    this.props.onChange && this.props.onChange(event.target.value, this.props.params);
    this.setState({value: event.target.value});
  }

  render() {
    return (
      <TextareaAutosize
        value={this.state.value}
        onChange={this.onChange}
        onBlur={() => this.props.onBlur(this.state.value, this.props.params)}
        className={`${inputClassNames(this.props, this.state)} input_auto-size`}
      />
    )
  }
}

function inputClassNames(props, state) {
  const {field, filter} = props;
  return classNames({
    input: true,
    input_filter: filter,
    input_warning: state && !state[field] ? true : false
  })
}
