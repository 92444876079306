import {actions} from '../consts';
import {createProject} from '../common/rulesHelpers/entityUtils';

export const get = (filter) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'get',
    success: set,
    url: `/api/v1/project/projects${filter}`,
  }
});

export const post = (params, organizationId) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'post',
    data: createProject(params, organizationId),
    success: (data) => get(projectsFilter('game', data.game)),
    url: '/api/v1/project/projects',
  }
});

export const set = (payload) => ({
  type: actions.SET_PROJECTS,
  payload,
  meta: {
    table: 'projects'
  }
});

export const patch = (data, uuid) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'patch',
    data: data,
    url: `/api/v1/project/projects/${uuid}`,
    success: (data) => get(projectsFilter('game', data.game))
  }
});

export const projectsFilter = (type, uuid) => {
  const filter = {where: {[type]: uuid}};
  return '?filter=' + JSON.stringify(filter);
}


