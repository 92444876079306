import toLower from 'lodash/toLower';
import { actions } from '../consts';

export default function filter_selector({ state=[], entities={}, parents={}, filter={} }) {
  switch (filter.type) {
    case actions.FILTER_ALL:
      return state;
    case actions.FILTER_UNCATEGORIZED:
      return state
        .filter(uuid => !parents[uuid].length);
    case actions.FILTER_CATEGORY:
      return state
        .filter(uuid => parents[uuid]
          .find(parent => parent === filter.uuid));
    case actions.FILTER_INPUT:
      return state
        .filter(uuid => toLower(entities[uuid].title.text)
          .indexOf(toLower(filter.title)) !== -1);
    default:
      return state;
  }
}
