import { Enum } from 'enumify';
import { initActions } from '@dized/ui/dist/redux/ActionHelper';
import { post } from '@dized/ui/dist/redux/RequestHelper';

const searchProxyUrl = '/api/v1/esp/';

class SearchProxyActions extends Enum {}

export default initActions(SearchProxyActions, {
  POST_TERMS: (params, projectUuid, locale) => post(`${searchProxyUrl}${projectUuid}/${locale}`, params),
});
