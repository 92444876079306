import {Input} from '../Input';
import {Icon} from '../Icon';
import propTypes from 'prop-types';
import React, {Component} from 'react';
import {limits} from '../../consts';

export default class RuleEditor extends Component {
  static propTypes = {
    style: propTypes.object,
    title: propTypes.string,
    icon: propTypes.object,
    onBlur: propTypes.func
  };

  static defaultProps = {
    title: ''
  };

  fixFocusPosition(e) {
    const {value} = e.target;
    e.target.value = '';
    e.target.value = value;
  };

  openAssetManager = (event) => {
    event.stopPropagation();
    this.props.showAsset({show: true, target: this.props.target});
  };

  render() {
    const {title, onBlur, onChange, target, style} = this.props;

    return (
      <div className="rule" style={style}>
        <div className="rule__icon" onClick={this.openAssetManager}>
          <Icon className="rule__img" src={target.icon.url} alt="" />
        </div>
        <Input
          title='title'
          defaultValue={title}
          maxLength={limits.MAX_RULE_LENGTH}
          onBlur={onBlur}
          autoFocus
          onChange={onChange}
          onFocus={this.fixFocusPosition}
          placeholder="Enter rule name" />
      </div>
    );
  }
}
