import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Select from "react-select";
import InfoList from "../../components/InfoList";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import RuleEditor from "../../components/RuleEditor";
import RuleBuilder from "../../components/RuleBuilder";
import {
  RelatedRules,
  QuestionConstructor,
} from "../../components/RelatedRules";
import Tag from "../../components/Tag";
import * as version from "../../actions/chosen_version_action";
import "./styles.scss";
import { getStringFromTO } from "../../common/utils/i18nUtils";
import {
  getDefaultColor,
  getLocale,
  setBackground,
} from "../../helpers/generic_helper";
import { imageSizes } from "../../consts";
import AssetSelector from "../../components/AssetSelector";

function onPreventUnload(event) {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = "";
}

class RuleOverview extends Component {
  constructor(props) {
    super(props);
    this.onUpdate = this.onUpdate.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onApply = this.onApply.bind(this);
    this.onUpdateParents = this.onUpdateParents.bind(this);
    this.handleValueComponent = this.handleValueComponent.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    this.state = {
      description: props.entities.rules[props.match.params.rule].description,
      applyButtonDisabled: true,
      showAssetSelector: false,
      assetTarget: {},
    };
  }

  toggleAssetSelector = (value) => {
    this.setState({ showAssetSelector: value });
  };

  updateAsset = ({ show, target }) => {
    this.toggleAssetSelector(show);
    this.setState({ assetTarget: target });
  }

  onAssetsClose = () => {
    this.toggleAssetSelector(false);
    this.props.onCancel();
  }

  onAssetsOpen = () => {
    this.updateAsset({ show: true, target: {} });
  }

  preventCloseAndReload() {
    window.addEventListener("beforeunload", onPreventUnload);
  }

  allowCloseAndReload() {
    window.removeEventListener("beforeunload", onPreventUnload);
  }

  updateContent = (params) => {
    this.preventCloseAndReload();
    this.props.updateVersionContent(params);
    this.setState({ applyButtonDisabled: false });
  };

  onUpdate(type = "", data = {}) {
    const uuid = this.props.match.params.rule;
    this.updateContent({
      [type]: { uuid, ...data },
    });
  }

  onUpdateParents(uuid = "", selectedOption) {
    const { entities } = this.props;

    if (selectedOption) {
      const categoryLookup = entities.menus;
      const primaryCategory = selectedOption.uuid;
      const category = categoryLookup[primaryCategory];

      this.updateContent({
        rule: {
          uuid: uuid,
          color: category.color,
        },
      });
    } else {
      this.updateContent({
        rule: {
          uuid: uuid,
          color: null,
        },
      });
    }

    this.updateContent({
      parent: {
        uuid,
        data: selectedOption ? [selectedOption.uuid] : [],
      },
    });
  }

  onApply(event) {
    const { rule, version } = this.props.match.params;
    this.props.removeVersionContent({
      processing: {
        uuid: rule,
      },
    });
    this.props.removeVersionContent({
      processing: {
        modules: {},
      },
    });
    this.props.removeVersionContent({
      processing: {
        questions: {},
      },
    });

    this.allowCloseAndReload();
    this.props.history.push(
      `/${this.props.match.params.orgId}/version/` + version
    );
  }

  onCancel(event) {
    const { version } = this.props.match.params;
    const ruleUuid = this.props.match.params.rule;

    if (!this.props.processing[ruleUuid]) {
      this.allowCloseAndReload();
      this.props.history.push(
        `/${this.props.match.params.orgId}/version/` + version
      );
      return;
    }

    const { parents, ...originalRule } = this.props.processing[ruleUuid];
    const { modules, questions } = this.props.processing;

    this.updateContent({
      rule: { ...originalRule, parents: ruleUuid },
      parent: { uuid: ruleUuid, data: parents },
      modules,
      questions,
    });
    this.props.removeVersionContent({ processing: { uuid: ruleUuid } });
    this.props.removeVersionContent({ processing: { modules } });
    this.props.removeVersionContent({ processing: { questions } });

    this.allowCloseAndReload();
    this.props.history.push(
      `/${this.props.match.params.orgId}/version/` + version
    );
  }

  handleOptions(options = [], entities = {}) {
    return options.map((option) => ({
      uuid: option,
      label: getStringFromTO(entities[option].title),
      style: {
        backgroundColor:
          this.props.palette[entities[option].color] || getDefaultColor(),
        color: "#fff",
        fontWeight: "500",
        borderBottom: "3px solid rgba(80, 90, 80, .2)",
      },
    }));
  }

  handleValueComponent(props) {
    return (
      <Tag
        sorted
        color={
          this.props.palette[this.props.entities.menus[props.value.uuid].color]
        }
        onClear={() => props.onRemove(props.value)}
      >
        {props.children}
      </Tag>
    );
  }

  onInputChange(event) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onAssetReceive = (asset) => {
    const { assetTarget } = this.state;

    if (assetTarget && assetTarget.uuid) {
      this.updateContent({
        rule: {
          ...assetTarget,
          icon: {
            ...assetTarget.icon,
            ...asset,
          },
        },
      });
    }
  };

  render() {
    const {
      details,
      palette,
      match,
      entities,
      assets,
      removeVersionContent,
      onCancel,
    } = this.props;
    const { assetTarget } = this.state;
    const locale = getLocale(details.locale);
    const { parents, menus, rules, modules, questions } = entities;
    const ruleDetails = rules[match.params.rule];
    const ruleParents = parents[ruleDetails.parents];
    const [ruleMainParent] = parents[ruleDetails.parents];
    const ruleParentDetails = ruleMainParent
      ? menus[ruleMainParent]
      : { color: getDefaultColor(), title: "Uncategorized" };


    let currentAsset = assetTarget && assetTarget.icon && assetTarget.icon.assetId;
    let imageSizeString = imageSizes.RULE_ICON_SIZE; // This is the pixel size of the category icon

    const attr = {
      mimeType: "image/png, image/jpeg",
      locale: locale ? locale : 'en-US',
      aspectRatio: "1:1",
      size: imageSizeString,
    };

    return (
      <div className="rule-overview">
        <div className="info-string">
          <InfoList updated={details.updated} />
          <div className="rule-overview__information">
            <div className="rule-overview__information-header">
              Parent Category
            </div>
            <Tag
              color={
                palette[ruleParentDetails.color] || ruleParentDetails.color
              }
            >
              {getStringFromTO(ruleParentDetails.title)}
            </Tag>
          </div>
          <div className="info-string__buttons">
            <Button onClick={this.onCancel}>Cancel</Button>
            <Button
              secondary
              onClick={this.onApply}
              disabled={this.state.applyButtonDisabled}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="wrap1140 content">
          <div className="content__block rule-overview__header">
            <div>
              <RuleEditor
                title={getStringFromTO(ruleDetails.title)}
                style={setBackground(palette[ruleDetails.color])}
                target={ruleDetails}
                onChange={(event) =>
                  this.onUpdate("rule", { title: { text: event.target.value } })
                }
                onUpdate={this.updateContent}
                showAsset={this.updateAsset}
              />
            </div>
            <div className="rule-overview__description">
              <Select
                multi
                clearable={false}
                simpleValue
                closeOnSelect={false}
                options={this.handleOptions(
                  this.props.menus,
                  this.props.entities.menus,
                  palette
                )}
                value={ruleParents.map((uuid) => ({
                  uuid,
                  label: getStringFromTO(menus[uuid].title),
                }))}
                valueKey="uuid"
                onChange={(selected) =>
                  this.onUpdateParents(ruleDetails.uuid, selected)
                }
                valueComponent={this.handleValueComponent}
              />
            </div>
          </div>
          <RuleBuilder
            locale={attr.locale}
            match={match}
            uuid={ruleDetails.uuid}
            layout={ruleDetails.modules}
            modules={entities.modules}
            onCancel={onCancel}
            onUpdate={this.updateContent}
            onRemove={removeVersionContent}
            showAsset={this.updateAsset}
            currentAsset={currentAsset}
          />
          <div className="rule__related-rules">
            <RelatedRules
              parentType={"rule"}
              rules={rules}
              modules={modules}
              details={ruleDetails}
              palette={palette}
              onUpdate={this.updateContent}
            />
          </div>
          <div className="rule__question-constructor">
            <QuestionConstructor
              locale={attr.locale}
              rule_uuid={ruleDetails.uuid}
              questions_array={this.props.questions}
              questions={questions}
              onEditQuestion={this.updateContent}
              onRemoveQuestion={this.updateContent}
            />
          </div>
        </div>

        <AssetSelector
          visible={this.state.showAssetSelector}
          assetId={currentAsset}
          onClose={this.onAssetsClose}
          locale={attr.locale}
          organizationId={this.props.organization.organizationId}
          onSelectAsset={(asset) => {
            this.onAssetReceive(asset);
            this.onAssetsClose();
          }}
        />
      </div>
    );
  }
}

class RuleOverviewContainer extends Component {
  componentDidMount() {
    const { details } = this.props,
      { params } = this.props.match,
      { getVersionContent } = this.props;

    if (isEmpty(details)) getVersionContent({ id: params.version });
  }

  componentWillReceiveProps(newProps) {
    const { processing, entities } = this.props;
    const { params } = this.props.match;
    const { modules, questions } = entities;

    if (
      !processing[newProps.match.params.rule] &&
      entities.rules[params.rule]
    ) {
      this.props.updateVersionContent({ processing: { modules, questions } });
      return this.props.updateVersionContent({
        processing: {
          ...entities.rules[params.rule],
          parents: entities.parents[params.rule],
        },
      });
    }
  }

  render() {
    const { fetching, entities, match } = this.props;
    return fetching ? (
      <Spinner />
    ) : !isEmpty(entities.rules) && entities.rules[match.params.rule] ? (
      <RuleOverview {...this.props} />
    ) : (
      "Rule doesn't exist."
    );
  }
}

const mapStateToProps = ({ db, ui, orm }) => {
  const { entities, details, menus, rules, palette, questions } = db.version;
  return {
    processing: ui.processing,
    fetching: ui.fetching,
    assets: orm.AssetData,
    organization: db.organization,
    entities,
    details,
    menus,
    rules,
    questions,
    palette,
  };
};

export default connect(mapStateToProps, {
  getVersionContent: version.get,
  updateVersionContent: version.update,
  removeVersionContent: version.remove,
  onCancel: version.cancel,
})(RuleOverviewContainer);
