import { TYPE } from '../../consts';
import { placeholder } from '../../consts';

// eslint-disable-next-line complexity
export default (module) => {
  const { data } = module;

  switch (data.type) {
    case TYPE.TEXT:
      return (`
        <div class="col s-3">
          ${data.data.text}
        </div>
      `);

    case TYPE.NOTE:
      return (`
        <div class="col s-3 note">
          ${data.data.text}
        </div>
      `);

    case TYPE.IMAGE:
      return (`
        <div class="col s-3">
          <img class="img" src="${data.src || placeholder.img}" alt="${data.alt || placeholder.text}" />
        </div>
      `);

    case TYPE.IMAGE_TEXT:
      return (`
        <div class="col s-1">
          <div class="row__img row__img_left">
            <img class="img" src="${data.src || placeholder.img}" alt="${data.alt || placeholder.text}"  />
          </div>
        </div>
        <div class="col s-2">
          ${data.data.text}
        </div>
      `);

    case TYPE.TEXT_IMAGE:
      return (`
        <div class="col s-2">
          ${data.data.text}
        </div>
        <div class="col s-1">
          <div class="row__img row__img_right">
            <img class="img" src="${data.src || placeholder.img}" alt="${data.alt || placeholder.text}"  />
          </div>
        </div>
      `);

    case TYPE.YOUTUBE: {
      const {data: {aspectRatio, end, id, start, width} = {}} = data;

      // - full screen button does not work -> hide it with fs=0
      // - reduce the branding content of the embedded video
      // - reduce/disable number of related videos -> rel=0 (but see YouTube documentation)
      return (`
        <div class="col s-3">
          <div class="youtube__iframe">
            <iframe
              frameborder="0"
              id="ytplayer"
              src="https://www.youtube.com/embed/${id}?${end ? `end=${end}&` : ''}fs=0&modestbranding=1&rel=0${start ? `&start=${start}` : ''}"
              type="text/html"
              width="${width}"
              height="${(width * aspectRatio).toFixed()}"
            >
            </iframe>
          </div>
        </div>
      `);
    }

    default:
      return;
  }
}
