import React, {Component} from 'react';
import MaterialIcon from 'material-icons-react';
import propTypes from 'prop-types';
import helpers from '../../helpers';

export default class Tag extends Component {

  static propTypes = {
	  sorted: propTypes.bool,
	  color: propTypes.string,
	  onClear: propTypes.func
  };

  static defaultProps = {
	  children: 'All rules',
	  sorted: false,
	  color: '#ff9900',
	  className: ''
  };

  render() {
	  const {children, color, onClear, sorted, className} = this.props;
	  return (
	    <div className={`tag ${className}`} style={helpers.generic.setBackground(color)}>
	      {sorted && <span className="tag__icon" onClick={onClear}>
	        <MaterialIcon icon="clear" color="#fff" size="10" />
	      </span>}
	      {children}
	    </div>
	  )
  }
}
