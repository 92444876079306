import {genericReduce} from '@dized/ui/dist/redux/OrmHelper';
import FolderActions from '../../actions/FolderActions';

export default function(session, action) {
  const {Folder} = session;
  switch (action.type) {
    default:
      genericReduce(Folder, FolderActions, action);
      break;
  }
  return session;
}
