import {actions} from '../consts';

const initialState = {
  subtitle: ''
};

export default (state = initialState, action) => {
  const {subtitle} = action.payload || {};
  switch (action.type) {
    case actions.SET_TITLE:
      return {
        ...state,
        subtitle: subtitle
      }
    case actions.CLEAR_TITLE:
      return {
        ...state,
        subtitle: ''
      };
    default:
      return state;
  }
}
