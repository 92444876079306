import { actions } from '../consts';
import merge from 'lodash/merge';

export default (state = {}, action) => {
  const { processing } = action.payload || {};
  switch (action.type) {
    case actions.UPDATE_VERSION:
    case actions.ADD_TO_VERSION:
      if (processing && processing.uuid) {
        return merge({}, state, {[processing.uuid]: processing});
      } else if (processing) {
        return merge({}, state, processing);
      } 
      return state;
        
    case actions.SAVE_TO_VERSION:
    case actions.REMOVE_FROM_VERSION:
      if (processing && processing.questions) {
        const stateCopy = merge({}, state);
        delete stateCopy.questions;
        return stateCopy;
      } else if (processing && processing.modules) {
        const stateCopy = merge({}, state);
        delete stateCopy.modules;
        return stateCopy;
      } else if (processing) {
        const stateCopy = merge({}, state);
        delete stateCopy[processing.uuid];
        return stateCopy;
      }
      return state;
    case actions.RESET_CONTENT:
      return {};
    default:
      return state;
  }
}
