import React from 'react';
import PropTypes from 'prop-types';

export const DropDown = ({className, options, ...remainder}) => (
  <select className="dropdown" {...remainder}>
    {options.map(({label, value}, index) => (
      <option
        key={index}
        value={value}
      >
        {label}
      </option>
    ))}
  </select>
);

DropDown.propTypes = {
  /** disable input or not */
  disabled: PropTypes.bool,
  /** called with event **NOT** then new value, i.e. `event.target.value` is what you are looking for */
  onChange: PropTypes.func.isRequired,
  /** list with label/value pairs */
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])
  })).isRequired,
  /** value (controlled component) */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

DropDown.defaultProps = {
  disabled: false,
};
