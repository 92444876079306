import {actions} from '../consts';

const initialState = {
  organizationId: '',
  organizationFullName: '',
  validOrganization: false,
  hasInitialized: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ORGANIZATION:
      const {payload} = action;

      return {
        ...state,
        organizationId: payload.organizationId || state.organizationId,
        organizationFullName: payload.organizationFullName || state.organizationFullName,
        validOrganization: payload.validOrganization || state.validOrganization,
        hasInitialized: true
      };
    default:
      return state;
  }
}
