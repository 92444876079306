import React, {Component} from 'react';
import {findDOMNode} from 'react-dom'
import MaterialIcon from 'material-icons-react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import ColorPicker from '../ColorPicker';
import {Input} from '../Input';
import {actions, limits} from '../../consts';
import helpers from '../../helpers';
import {getStringFromTO} from '../../common/utils/i18nUtils';
import {TextArea} from '../Input';
import {Icon} from '../Icon';
import ChildTag from '../ChildTag'

export class SmartMenu extends Component {

  constructor(props) {
    super(props);
    this.onEditorMode = this.onEditorMode.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleRemoveAttempt = this.handleRemoveAttempt.bind(this);
    this.openAssetManager = this.openAssetManager.bind(this);
  }

  onEditorMode(event) {
    event.stopPropagation();
    this.props.onUpdate({processing: this.props.data});
  }

  handleFilter() {
    this.props.onFilter({
      type: actions.FILTER_CATEGORY,
      payload: this.props.data
    });
  }

  handleRemoveAttempt(event) {
    event.stopPropagation();
    const {data, onAttempt} = this.props;
    !this.props.hasChildren && onAttempt({type: 'menu', uuid: data.uuid});
  }

  openAssetManager(event) {
    event.stopPropagation();

    this.props.showAsset({
      show: true,
      target: this.props.data
    });
  }

  render() {
    const {data, processing, palette, parents} = this.props;

    const mode = processing[data.uuid];
    const numOfChildren = Object.keys(parents).filter(rule => parents[rule].includes(data.uuid)).length;

    return (
      !mode
        ? <div className="menu" style={helpers.generic.setBackground(palette[data.color])}
          onClick={this.handleFilter} onDoubleClick={this.onEditorMode}>
          <div className="menu__icon" onClick={this.openAssetManager}>
            <Icon className="menu__img" src={data.icon.url} alt="" />
          </div>
          <div className="menu__title">{getStringFromTO(data.title)}</div>
          <div className="menu__buttons">
            <ChildTag numOfChildren={numOfChildren} />
            <div className="menu__button" onClick={this.onEditorMode}>
              <MaterialIcon icon="mode_edit" color="#fff" />
            </div>
            <div className="menu__button" onClick={this.handleRemoveAttempt}>
              <MaterialIcon icon="delete_forever" color="#fff" />
            </div>
          </div>
        </div>
        : <MenuEditor {...this.props} />
    )
  }
}

class MenuEditor extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSave = this.onSave.bind(this);
    this.openAssetManager = this.openAssetManager.bind(this);

    this.state = {
      ...this.props.data,
      description: {...this.props.data.description}
    };
  }

  openAssetManager(event) {
    event.stopPropagation();
    this.props.showAsset({
      show: true,
      target: this.state
    });
  }

  onChange(event, type) {
    const {target} = event;
    type === 'title'
      ? this.setState({title: {...this.state.title, text: target.value}})
      : this.setState({description: {...this.state.description, text: target.value}})
  }

  onBlur(event) {
    event.stopPropagation();
    this.props.onUpdate({
      menu: this.state
    });
  }

  onSave(event) {
    event.stopPropagation();
    if (this.props.data.title.text) {

      this.props.onUpdate({
        menu: this.state
      });

      this.props.onDelete({
        type: actions.SAVE_TO_VERSION,
        processing: this.state
      });
    }
  }

  onCancel(event) {
    event.stopPropagation();

    const {processing, onDelete, onUpdate, onSelectColor} = this.props;
    const categoryUuid = this.state.uuid;
    const reversionColor = processing[categoryUuid].color;

    if (!processing[categoryUuid].title.text) {
      onDelete({
        type: 'menu',
        uuid: categoryUuid
      });

    } else {
      onUpdate({menu: processing[categoryUuid]});
      onSelectColor(reversionColor, categoryUuid);
    }
    onDelete({processing: this.state});
  }

  onSelectColor = (color) => {
    const {uuid} = this.state;
    this.setState({color});
    this.props.onSelectColor(color, uuid);
  }

  focusInput = (component) => {
    if (component) {
      findDOMNode(component).focus();
    }
  };

  handleEnterKey = (event) => {
    this.onBlur(event);
    this.onSave(event);
  }

  handleEscapeKey = (event) => {
    this.onCancel(event);
  }

  componentDidUpdate(prevProps) {
    // NOTE: The parent component updates the icon in props, 
    // so we need to sync the state to reflect those changes.
    if (prevProps.data.icon.assetId !== this.props.data.icon.assetId) {
      this.setState({
        icon: { ...this.props.data.icon },
      });
    }
  }

  render() {
    const {data, palette} = this.props;

    return (
      <div>
        <div className="menu" style={helpers.generic.setBackground(palette[data.color])}>
          <div className="menu__icon" onClick={this.openAssetManager}>
            <Icon className="menu__img" src={data.icon.url} alt="" />
          </div>
          <Input
            ref={this.focusInput}
            title="title"
            maxLength={limits.MAX_CAT_LENGTH}
            defaultValue={getStringFromTO(data.title)}
            placeholder="Enter category name"
            onChange={(event) => this.onChange(event, 'title')}
            onBlur={this.onBlur}
            onEnterPressed={this.handleEnterKey}
            onEscapePressed={this.handleEscapeKey} />
          <div className="menu__buttons">
            <div className='menu__button' onClick={this.onSave}>
              <MaterialIcon icon="done" color="#fff" />
            </div>
            <div className='menu__button' onClick={this.onCancel}>
              <MaterialIcon icon="cancel" color="#fff" />
            </div>
          </div>
        </div>
        <div>
          <div className="menu_colors_wrapper">
            <ColorPicker
              color={data.color}
              palette={palette}
              onSelectColor={this.onSelectColor} />
          </div>
          <TextArea className='textarea textarea_menu_description'
            value={getStringFromTO(this.state.description)}
            name="description"
            maxLength={limits.MAX_CAT_DESC_LENGTH}
            placeholder='Write a short description for the category'
            onBlur={this.onBlur}
            onChange={(event) => this.onChange(event, 'description')}
            onEnterPressed={this.handleEnterKey}
            onEscapePressed={this.handleEscapeKey} />
        </div>
      </div>
    );
  }
}

export class Menu extends Component {
  static propTypes = {
    color: propTypes.string,
    icon: propTypes.string,
    onFilter: propTypes.func,
    numberOfRules: propTypes.number
  };

  render() {
    const {children, icon, onFilter} = this.props;
    return (
      <div className={menuClassNames(this.props)} onClick={onFilter}>
        {icon && <MaterialIcon icon={icon} color="rgba(255,255,255,.8)" size="small" />}
        <div className="menu__title">{children}</div>
        <ChildTag numOfChildren={this.props.numberOfRules} />
      </div>
    )
  }
}

function menuClassNames(props) {
  return classNames({
    menu: true,
    'menu_show-all': props.show_all,
    menu_uncategorized: props.uncategorized
  });
}
