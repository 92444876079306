import { actions } from '../consts';

const INIT_STATE = { show: false };

export default (state=INIT_STATE, action) => {
  switch (action.type) {
    case actions.SHOW_MODAL:
      return { show: true, ...action.payload };
    case actions.HIDE_MODAL:
    case actions.CONFIRM:
    case actions.CANCEL:
      return { show: false };
    case actions.RESET_CONTENT:
      return INIT_STATE;
    default:
      return state;
  }
}
