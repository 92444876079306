import React, {Component} from 'react'
import {DropTarget} from 'react-dnd'
import SmartListItem from './TreeListItem'
import propTypes from 'prop-types';

const target = {
  drop(props) {
    props.drop();
  },

  hover(props, monitor) {
    const {uuid: draggedId, parent, items} = monitor.getItem();

    if (!monitor.isOver({shallow: true})) {
      return;
    }

    const descendantNode = props.find(props.parent, items);
    if (descendantNode) {
      return;
    }

    if (parent === props.parent || draggedId === props.parent) {
      return;
    }

    props.move(draggedId, props.uuid, props.parent)
  }
};


class TreeList extends Component {

  static propTypes = {
    items: propTypes.array.isRequired,
    parent: propTypes.any,
    order: propTypes.array,
    move: propTypes.func.isRequired,
    find: propTypes.func.isRequired,
    drop: propTypes.func.isRequired
  };

  render() {

    const {
      connectDropTarget, items, parent, component, move, find, drop, idDragging, ...rest
    } = this.props;

    return connectDropTarget(
      <div className="tree-list">
        {items.map((item, index) => {
          return <SmartListItem
            key={item.uuid}
            index={index}
            uuid={item.uuid}
            parent={parent}
            item={item}
            component={component}
            move={move}
            find={find}
            drop={drop}
            {...rest}
          />
        })}
      </div>
    )
  }
}

export default DropTarget('ITEM', target, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget()
}))(TreeList)

