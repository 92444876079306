import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import games_reducer from './games_reducer';
import organization_reducer from './organization_reducer';
import projects_reducer from './projects_reducer';
import versions_reducer from './versions_reducer';
import {
  questions_entities_reducer,
  modules_entities_reducer,
  menus_entities_reducer,
  rules_entities_reducer,
  parents_entities_reducer
} from './entities_reducer';
import { details_reducer, menus_reducer, rules_reducer, palette_reducer, questions_reducer } from './details_reducer';
import fetching_reducer from './fetching_reducer';
import filter_reducer from './filter_reducer';
import modal_reducer from './modal_reducer';
import processing_reducer from './processing_reducer';
import title_reducer from './title_reducer';
import tab_reducer from './tab_reducer';
import ormReducer from '../common/reducers/ormReducer';

const createRootReducer = (history) => {
  const { routerReducer } = createReduxHistoryContext({ history });

  return combineReducers({
    router: routerReducer,
    vendor: combineReducers({
      routing: routerReducer
    }),
    db: combineReducers({
      games: games_reducer,
      organization: organization_reducer,
      version: combineReducers({
        entities: combineReducers({
          questions: questions_entities_reducer,
          modules: modules_entities_reducer,
          parents: parents_entities_reducer,
          menus: menus_entities_reducer,
          rules: rules_entities_reducer
        }),
        details: details_reducer,
        menus: menus_reducer,
        rules: rules_reducer,
        questions: questions_reducer,
        palette: palette_reducer
      }),
      projects: projects_reducer,
      versions: versions_reducer
    }),
    ui: combineReducers({
      fetching: fetching_reducer,
      processing: processing_reducer,
      filter: filter_reducer,
      modal: modal_reducer,
      tab: tab_reducer,
      title: title_reducer
    }),
    orm: ormReducer,
  });
};

export default createRootReducer;
