import React, {Component} from 'react';
import propTypes from 'prop-types';

export default class ChildTag extends Component {

  static propTypes = {
	  numOfChildren: propTypes.number.isRequired
  };

  render() {
	  return (
	    <div className={'child-tag'}>
        <div className={'child-tag-text'}>
          {this.props.numOfChildren}
        </div>
	    </div>
	  )
  }
}
