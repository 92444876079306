import propTypes from 'prop-types';
import React, {Component} from 'react';
import MaterialIcon from 'material-icons-react';
import {getDefaultColor} from '../../helpers/generic_helper';
import rltIcon from './rlt_icon.png';

export default class Subheader extends Component {

  static propTypes = {
    icon: propTypes.string,
    title: propTypes.string,
    description: propTypes.string
  };

  renderIcon() {
    const {icon} = this.props;

    if (icon) {
      if (icon === 'dized-rlt') {
        return <img src={rltIcon} alt="RCT Icon" />
      }

      return <MaterialIcon icon={icon} color={getDefaultColor()} />
    }

    return null;
  }

  render() {
    const {title, description} = this.props;
    return (
      <div className="subheader">
        <span className="subheader__icon">
          {this.renderIcon()}
        </span>
        <span className="subheader__title">{title}</span>
        <span className="subheader__description">{description}</span>
      </div>
    )
  }
}
