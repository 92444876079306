import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';
import * as version from '../../actions/chosen_version_action';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {actions} from '../../consts';

class Tabs extends Component {

  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.tab
    };
  }

  onClick = (tab) => {
    this.setState({isActive: tab});
    this.props.updateVersionContent({
      type: actions.SET_TAB,
      tab: tab
    });
  };

  render() {

    const {children} = this.props;
    const isActive = this.state.isActive;

    return (
      <div className="tabs">
        <div className="tab_container">
          <div className="tab_list">
            {children.map((child) => {
              const {tab} = child.props;
              return (
                <Tab
                  isActive={isActive}
                  enabled={child.props.enabled || true}
                  key={tab}
                  tab={tab}
                  onClick={this.onClick}
                />
              );
            })}
          </div>
        </div>
        <div className="tab_content">
          {children.map((child) => {
            if (child.props.tab !== isActive) {
              return undefined;
            }
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({db, ui}) => {
  return {
    tab: ui.tab
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {updateVersionContent: version.update}
  )(Tabs)
);
