import {attr, fk, Model} from 'redux-orm';

export default class Asset extends Model {
  static modelName = 'Asset';

  static options = {
    idAttribute: 'id',
  };

  static fields = {
    id: attr(),
    folderId: fk({
      to: 'Folder',
      as: 'folder',
      relatedName: 'assets',
    }),
    name: attr(),
    locale: attr(),
  };

  toString() {
    return `${this.modelName}: ${this.name} (${this.id})`;
  }
}
