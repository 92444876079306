import orm from '../orm';
import * as ORMReducers from './ORMReducers/index';

export default function ormReducer(dbState = orm.getEmptyState(), action) {
  const sess = orm.session(dbState);
  const updated = Object.values(ORMReducers).reduce(
    (session, reducer) => reducer(session, action),
    sess
  ); 
  return updated.state;
}
