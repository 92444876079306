import { actions } from '../consts';
import {createRuleVersion} from '../common/rulesHelpers/entityUtils';

export const get = (params) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'get',
    success: set,
    url: `/api/v1/rules/versions${versionFilter(params)}`,
  }
});

export const post = (params) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'post',
    data: createRuleVersion(params),
    success: (data) => get({ id: data.projectUuid }),
    url: '/api/v1/rules/versions',
  }
});

export const set = (payload) => ({
  type: actions.SET_VERSIONS,
  payload,
  meta: {
    table: 'versions'
  }
});

function versionFilter(params) {
  const filter = {where: {'projectUuid' : params.id }};
  return '?filter=' + JSON.stringify(filter);
}
