import React, { Component } from 'react';
import propTypes from 'prop-types';

export default class ColorPicker extends Component {
  static propTypes = {
    palette: propTypes.array.isRequired,
    onSelectColor: propTypes.func.isRequired
  };

  render() {
    const { palette, onSelectColor } = this.props;
    return (
      <div className="color-picker">
        <ul className="color-picker__list">
          { palette 
            ? palette.map((color, index) => (
              <li key={index} onClick={() => onSelectColor(index)} style={{ backgroundColor: color }} />
            ))
            : 'Define a palette'}
        </ul>
      </div>
    );
  }
}
