import React, {Component} from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import propTypes from 'prop-types';

export default class SortableList extends Component {

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragEndCallback = this.onDragEndCallback.bind(this);
    this.state = {
      order : []
    }
  }

  static propTypes = {
	  component: propTypes.func.isRequired,
	  isDragDisabled: propTypes.bool
  };

  static defaultProps = {
	  collection: [],
	  entities: {},
	  isDragDisabled: false
  };

  reorder = (list, moveFromIndex, moveToIndex) => {
	  const result = Array.from(list);
	  const [removed] = result.splice(moveFromIndex, 1);
	  result.splice(moveToIndex, 0, removed);
	  return result;
  };

  hoc(Component) {
	  return (props) => <Component {...props} />
  };

  onDragEnd(result) {
	  this.callback && this.callback(result, this.props.target, this.props.collection, this.reorder);
  }

  onDragEndCallback (callback) {
	  this.callback = callback;
  };

  render() {
	  const {collection, type, entities, component, processing, isDragDisabled, ...rest} = this.props;

	  return (
	    <DragDropContext onDragEnd={ this.onDragEnd }>
	      <Droppable droppableId={type}>
	        {(provided) => {
	          return <ul className="box" ref={provided.innerRef} style={{ overflow: 'auto' }}>
	            { collection.map((key, index) =>
	              <Draggable key={key} draggableId={key} index={parseInt(index, 10)} isDragDisabled={isDragDisabled}>
	                {(provided) => (
	                  <li key={entities[key].uuid}>
	                    <div
	                      ref={provided.innerRef}
	                      {...provided.draggableProps}
	                      {...provided.dragHandleProps}
	                      style={{ ...provided.draggableProps.style, paddingBottom: '3px' }}>
	                      {this.hoc(component)({
	                        data: entities[key],
	                        collection,
	                        entities,
	                        processing,
	                        callback : this.onDragEndCallback,
	                        ...rest })}
	                    </div>
	                    { provided.placeholder }
	                  </li>
	                )}
	              </Draggable>
	            )}
	            { provided.placeholder }
	          </ul>
	        }}
	      </Droppable>
	    </DragDropContext>
	  )
  }
}
