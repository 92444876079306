import {attr, fk, Model} from 'redux-orm';

export default class Folder extends Model {
  static modelName = 'Folder';

  static options = {
    idAttribute: 'uuid',
  };

  static fields = {
    uuid: attr(),
    parentId: fk({
      to: 'Folder',
      as: 'folder',
      relatedName: 'childFolders',
    }),
    name: attr(),
  };

  toString() {
    return `${this.modelName}: ${this.name} (${this.uuid})`;
  }
}
