import React, { Component } from 'react';
import { Block } from 'slate';
import { CHILD_REQUIRED, CHILD_TYPE_INVALID } from 'slate-schema-violations';

import TextWidget from '../TextWidget';
import { BLOCK } from '../../consts';

const note_schema = {
  document: {
    nodes: [
      { types: [BLOCK.NOTE_TITLE], min: 1, max: 1 },
      { types: [BLOCK.NOTE_TEXT], min: 1 },
    ],
    normalize: (change, violation, { node, child, index }) => {
      switch (violation) {
        case CHILD_TYPE_INVALID:
          return change.setNodeByKey(
            child.key,
            index === 0 ? BLOCK.NOTE_TITLE : BLOCK.NOTE_TEXT
          );
        case CHILD_REQUIRED:
          const block = Block.create(index === 0 ? BLOCK.NOTE_TITLE : BLOCK.NOTE_TEXT);
          return change.insertNodeByKey(node.key, index, block);
        default:
          return;
      }
    },
  }
}


export default class NoteWidget extends Component {
  render() {
    return (
      <div className="col s-3 note rule-builder__note">
        <TextWidget {...this.props} schema={note_schema} />
      </div>
    )
  }
}
