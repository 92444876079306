import React, {Component} from 'react';
import MaterialIcon from 'material-icons-react';
import classNames from 'classnames';
import {setBackground} from '../../helpers/generic_helper';
import {getStringFromTO} from '../../common/utils/i18nUtils';
import propTypes from 'prop-types';
import FaqPreview from '../FaqPreview';

const iconName = (isOpen) => classNames({
  keyboard_arrow_right: !isOpen,
  keyboard_arrow_down: isOpen,
});

export default class Faq extends Component {

  static propTypes = {
    data: propTypes.object.isRequired,
    onAttempt: propTypes.func.isRequired,
    onUpdate: propTypes.func.isRequired,
    updateSize: propTypes.func,
    hasExpanded: propTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {open: false};
  }

  componentDidMount() {
    this.props.callback(this.onDragEnd);
  }

  onToggleMode = () => {
    const {open} = this.state;
    const {updateSize, hasExpanded} = this.props;

    updateSize && updateSize()

    if (hasExpanded) {
      this.setState({open: !hasExpanded});
    } else {
      this.setState({open: !open});
    }
  }

  onHandleUpdate = (data) => {
    this.props.onUpdate({
      processing: {
        ...data
      }
    });
  }

  handleRemoveAttempt = (event) => {
    event.stopPropagation();
    const {data, onAttempt} = this.props;
    onAttempt({
      type: 'question',
      uuid: data.uuid
    });
  }

  onDragEnd = (result, target, collection, sortingMethod) => {
    if (!result.destination) return;

    const sorting = sortingMethod(
      collection,
      result.source.index,
      result.destination.index,
    );

    this.props.onUpdate({
      'questions': sorting.reduce((questions, key) => {
        questions[key] = target[key];
        return questions;
      }, {}),
    });
  };

  styles = (state, props) => {
    const {data, palette} = props;
    return setBackground(palette[data.color]);
  };

  openFaqEditor = (params, data) => {
    this.props.onDelete({processing: {uuid: data.uuid}});
    this.props.onEditClick(`/${params.orgId}/version/${params.id}/faq/${data.uuid}`);
  };

  render() {
    const {data, params} = this.props;
    let {open} = this.state;
    const style = this.styles(this.state, this.props);
    open = this.props.hasExpanded ? this.props.hasExpanded : open;
    const title = getStringFromTO(data.title);

    return (
      <div onDoubleClick={() => this.openFaqEditor(params, data)}>
        <div className="faq"
          style={{...style, border : 'none'}} onClick={this.onToggleMode}>
          <div title={title} className="faq__title">{title}
          </div>
          <div className="faq__buttons">
            <div className="faq__button" onClick={() => this.openFaqEditor(params, data)}>
              <MaterialIcon icon="mode_edit" color="#fff"/>
            </div>
            <div className="faq__button" onClick={this.handleRemoveAttempt}>
              <MaterialIcon icon="delete_forever" color="#fff"/>
            </div>
            <MaterialIcon icon={iconName(open)} color="#fff"/>
          </div>
        </div>
        {open && <FaqPreview {...this.props} open={true}/>}
      </div>
    )
  }
}



