import {attr, fk, Model} from 'redux-orm';

export default class Asset extends Model {
  static modelName = 'AssetData';

  static options = {
    idAttribute: 'id',
  };

  static fields = {
    id: attr(),
    assetId: fk({
      to: 'Asset',
      as: 'asset',
      relatedName: 'assetData',
    }),
    name: attr(),
    locale: attr(),
    type: attr(),
    size: attr(),
    checksum: attr(),
    created: attr(),
    modified: attr(),
    meta: attr(),
  };

  toString() {
    return `${this.modelName}: ${this.name} (${this.uuid})`;
  }
}
