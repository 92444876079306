export const TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  NOTE: 'note',
  IMAGE_TEXT: 'image_text',
  TEXT_IMAGE: 'text_image',
  GALLERY: 'gallery',
  YOUTUBE: 'youtube',
};

export const BLOCK = {
  NOTE_TITLE: 'note_title',
  NOTE_TEXT: 'note_text',
  P: 'paragraph',
  H1: 'heading-one',
  H2: 'heading-two',
  H3: 'heading-three',
  II: 'inline-image'
};

export const MARK = {
  EM: 'italic',
  SPOILER: 'spoiler',
  STRONG: 'bold',
  U: 'underline'
};

export const placeholder = {
  img: "https://dummyimage.com/250x250/cccccc/000000&text=Click+to+set+image",
  text: "Placeholder"
}
