import React, {Component} from 'react';
import {Input, TextArea} from '../Input';
import Button from '../Button';
import Placeholder from '../Placeholder';
import QuestionPreview from '../QuestionPreview';
import MaterialIcon from 'material-icons-react';
import values from 'lodash/values';
import toLower from 'lodash/toLower';
import helpers from '../../helpers';
import find from 'lodash/find';
import {getStringFromTO} from '../../common/utils/i18nUtils';
import {setBackground} from '../../helpers/generic_helper';
import {initNewFaq, createNewPageObject} from '../../helpers/entities_helper';

import './styles.scss';
import propTypes from 'prop-types';
import {limits} from '../../consts';

function ruleBackground(props, palette) {
  return setBackground(palette[props.color]);
}

export class RelatedRules extends Component {

  constructor(props) {
    super(props);
    this.state = {filter: ''};
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  static propTypes = {
    parentType: propTypes.string.isRequired
  };

  handleUpdate(uuid) {

    const {details, onUpdate, parentType} = this.props;
    const {related} = details;

    const new_related = related.find(related_uuid => related_uuid === uuid)
      ? related.filter(related_uuid => related_uuid !== uuid)
      : related.concat([uuid]);

    onUpdate({[parentType]: {...details, related: new_related}}, uuid)
  }

  onFilterChange(event) {
    const {value} = event.target;
    this.setState({filter: value});
  }

  render() {
    const {rules, details, modules, palette, parentType} = this.props;
    return (
      <div>
        {parentType !== 'question' && <h3>Related Rules</h3>}
        <div className="rr">
          <div className="rr__headers">
            <div className="rr__header rr__header_space-between">
              <div className="rr__header">
                <span className="rr__icon">
                  <MaterialIcon icon='reorder' color='#202020' size='24' />
                </span>
                All Rules
              </div>
              <span className='rr__clear-filters'
                onClick={() => this.setState({filter: ''})}>clear filter</span>
            </div>
            <div className="rr__header">
              <span className="rr__icon">
                <MaterialIcon icon='link' color='#202020' size='24' />
              </span>
              {parentType === 'question' ? 'Connected Rules' : 'Related Rules'}
            </div>
          </div>
          <div className="rr__rules">
            <div className="rr__column">
              <div className="rr__input">
                <Input className='input input_filter' value={this.state.filter}
                  onChange={this.onFilterChange} placeholder='Filter by Rule name or ID' />
              </div>
              <div
                className="rr__column_left"
                style={{ width: '100%', height: '100%', overflowY: 'auto' }}
              >
                <div className="rr__list">
                  {
                    values(rules)
                      .filter(rule => rule.uuid !== details.uuid
                        && toLower(rule.title.text).indexOf(toLower(this.state.filter)) !== -1)
                      .map(rule => details.related.find(uuid => uuid === rule.uuid)
                        ? <Rule key={rule.uuid} palette={palette} {...rule} selected
                          onUpdate={this.handleUpdate} />
                        : <Rule key={rule.uuid} palette={palette} {...rule}
                          onUpdate={this.handleUpdate} />)
                  }
                </div>
              </div>
            </div>
            <div
              className="rr__column rr__column_related rr_bg_gray"
              style={{ width: '100%', height: '100%', overflowY: 'auto' }}
            >
              <div className="rr__list">
                {
                  details.related.map(uuid => rules[uuid]
                    ? <RelatedRule key={uuid} details={rules[uuid]} modules={modules} palette={palette} />
                    : <div key={uuid}>Rule doesn't exist</div>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class RelatedRule extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false};
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    const {open} = this.state;
    this.setState({open: !open})
  }

  render() {
    const {details, modules, palette} = this.props;
    return this.state.open
      ? <div>
        <div className='rr__rule-wrap' onClick={this.onToggle} style={ruleBackground(details, palette)}>
          <div className='rr__rule'>
            <span className="rr__icon">
              <MaterialIcon icon='reorder' color='#202020' size='24' />
            </span>
            {getStringFromTO(details.title)}
          </div>
          <span className="rr__icon">
            <MaterialIcon icon='star' color='#fff' size='20' />
          </span>
        </div>
        <div className="rr__desc">
          {details.modules.map(module => <p key={module}>{modules[module].value}</p>)}
        </div>
      </div> :
      <div className='rr__rule-closed' onClick={this.onToggle}>
        <div className='rr__rule-wrap' style={ruleBackground(details, palette)}>
          <div className='rr__rule'>
            <span className="rr__icon">
              <MaterialIcon icon='reorder' color='#202020' size='24' />
            </span>
            {getStringFromTO(details.title)}
          </div>
        </div>
        <span className="rr__icon rr__icon_closed">
          <MaterialIcon icon='star_border' color='#202020' size='22' />
        </span>
      </div>
  }
}

class Rule extends Component {
  render() {
    const {selected, palette, onUpdate, ...details} = this.props;
    return (
      <div className='rr__rule-wrap' style={ruleBackground(details, palette)}
        onClick={() => onUpdate(details.uuid)}>
        <div className='rr__rule'>
          <span className="rr__icon">
            <MaterialIcon icon='reorder' color='#202020' size='24' />
          </span>
          {getStringFromTO(details.title)}
        </div>
        <span className="rr__icon">
          {
            selected
              ? <MaterialIcon icon='check_box' color='#202020' size='24' />
              : <MaterialIcon icon='check_box_outline_blank' color='#202020' size='24' />
          }
        </span>
      </div>
    )
  }
}

export class QuestionConstructor extends Component {
  constructor(props) {
    super(props);
    this.state = {...this.initState()};
    this.onCreateNewQuestion = this.onCreateNewQuestion.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputClear = this.onInputClear.bind(this);
    this.onChangeQuestion = this.onChangeQuestion.bind(this);
    this.onUpdateQuestion = this.onUpdateQuestion.bind(this);
  }

  initState = () => {
    const {locale} = this.props;

    return {
      ...initNewFaq({
        title: '',
        locale,
        pageObject: createNewPageObject(this.props.rule_uuid),
      }),
      editor: false,
      titleInputClassName: 'input input_filter'
    };
  }

  onCreateNewQuestion(event) {
    event.preventDefault();
    const {onEditQuestion, rule_uuid} = this.props;
    const {editor, ...data} = this.state;

    if (this.state.title.text) {
      this.setState({titleInputClassName: 'input input_filter'})
      helpers.entity.createQuestion({
        ...data,
        related: [rule_uuid],
        method: onEditQuestion
      });
      this.onInputClear();
      this.setState({...this.initState()})
    } else {
      this.setState({titleInputClassName: 'input input_warning'})
    }
  }

  onChangeQuestion(uuid) {
    const {questions} = this.props;
    const {title, answer, short_answer} = questions[uuid];

    this.setState({
      title, answer, short_answer, editor: uuid
    });
  }

  onUpdateQuestion(event) {
    event.preventDefault();
    const {editor, ...state} = this.state;

    if (this.state.title.text) {
      this.props.onEditQuestion({
        question: {
          uuid: editor,
          ...state
        }
      });
      this.onInputClear();
    } else {
      this.setState({titleInputClassName: 'input input_warning'})
    }
  }

  onInputChange(event) {
    const {value, name} = event.target;

    if (name === 'answer') {
      const index = this.state.answer.findIndex(answer => answer.type === 'long_answer');
      const answer = [...this.state.answer];

      answer[index] = {
        ...answer[index],
        text: {
          ...answer[index].text,
          text: value
        }
      };
      this.setState({answer: answer})
    } else {
      this.setState({
        [name]: {
          ...this.state[name],
          text: value,
          context: {
            ...this.state[name].context
          }
        }
      });
    }
  }

  onInputClear(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      ...this.initState()
    });
  }

  renderQuestionsList(props) {
    const {rule_uuid, questions_array, questions, ...rest} = props;
    const related_questions = questions_array
      .filter(question => find(questions[question].related, (rule) => rule === rule_uuid))
      .map(question => {
        return (
          <QuestionPreview
            key={question}
            ruleUuid={rule_uuid}
            question={questions[question]}
            onChangeQuestion={this.onChangeQuestion}
            {...rest} />
        );
      });
    if (!related_questions.length) {
      return <Placeholder>Create at least one question</Placeholder>;
    }
    return related_questions;

  }

  render() {
    return (
      <React.Fragment>
        <h3>Questions Constructor</h3>
        <div className="rr">
          <div className="rr__headers">
            <div className="rr__header rr__header_space-between">
              <div className="rr__header">
                <span className="rr__icon">
                  <MaterialIcon icon="help_outline" color="#202020" size="24" />
                </span>
                Create Question
              </div>
            </div>
            <div className="rr__header">
              <span className="rr__icon">
                <MaterialIcon icon="link" color="#202020" size="24" />
              </span>
              Related Questions
            </div>
          </div>
          <div className="rr__rules">
            <div className="rr__column">
              <form className="rr__form">
                <Input
                  className={this.state.titleInputClassName}
                  name="title"
                  maxLength={limits.MAX_FAQ_LENGTH}
                  value={getStringFromTO(this.state.title)}
                  onChange={this.onInputChange}
                  placeholder="Question" />
                <Input
                  className="input input_filter"
                  name="short_answer"
                  maxLength={limits.MAX_FAQ_SHORT_LENGTH}
                  value={getStringFromTO(this.state.short_answer)}
                  onChange={this.onInputChange}
                  placeholder="Short answer" />
                <TextArea
                  className="input input_filter textarea"
                  name="answer"
                  value={getStringFromTO(this.state.answer.filter(answer =>
                    answer.type === 'long_answer')[0].text)}
                  onChange={this.onInputChange}
                  placeholder="Freeform answer" />
                {this.state.editor === false ?
                  <div className="rr__buttons">
                    <Button secondary onClick={this.onCreateNewQuestion}>Add Question</Button>
                    <Button onClick={this.onInputClear}>Clear</Button>
                  </div> :
                  <div className="rr__buttons">
                    <Button secondary onClick={this.onUpdateQuestion}>Confirm</Button>
                    <Button onClick={this.onInputClear}>Cancel</Button>
                  </div>}
              </form>
            </div>
            <div
              className="rr__column rr__column_related"
              style={{ width: '100%', height: '100%', overflowY: 'auto' }}
            >
              <div className="rr__list">
                {this.renderQuestionsList(this.props)}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
