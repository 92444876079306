// Middleware
export const API_MIDDLEWARE = 'middleware::API_CALL';
export const ATTEMPT = 'middleware::ATTEMPT';
export const CONFIRM = 'middleware::CONFIRM';
export const CANCEL = 'middleware::CANCEL';

// UI
export const FETCHING_START = 'ui::FETCHING_START';
export const FETCHING_STOP = 'ui::FETCHING_STOP';

export const SHOW_MODAL = 'ui::SHOW_MODAL';
export const HIDE_MODAL = 'ui::HIDE_MODAL';

export const SET_TAB = 'ui::UPDATE';

export const SET_TITLE = 'ui::SET_TITLE';
export const CLEAR_TITLE = 'ui::CLEAR_TITLE';

// Filters
export const FILTER_ALL = 'filter::ALL';
export const FILTER_CATEGORY = 'filter::CATEGORY';
export const FILTER_UNCATEGORIZED = 'filter::UNCATEGORIZED';
export const FILTER_INPUT = 'filter::FILTER_INPUT';


// Set fetched data
export const SET_GAMES = 'games::SET';
export const SET_PROJECTS = 'projects::SET';
export const SET_VERSIONS = 'versions::SET';
export const SET_VERSION = 'version::SET';
export const SET_ORGANIZATION = 'organization::SET';

// General actions
export const ADD_TO_VERSION = 'version::ADD_TO';
export const SAVE_TO_VERSION = 'version::SAVE';
export const UPDATE_VERSION = 'version::UPDATE';
export const REMOVE_FROM_VERSION = 'version::REMOVE';

// Generic
export const RESET_CONTENT = 'content::RESET';
