import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createWidgetModule, Widget } from "./components/Widget";
import toHTML from "./components/Layout";

import TextWidget from "./widgets/TextWidget";
import NoteWidget from "./widgets/NoteWidget";
import ImageWidget from "./widgets/ImageWidget";
import ImageTextWidget from "./widgets/ImageTextWidget";
import YouTubeWidget from "./widgets/YouTubeWidget";

import React, { Component } from "react";
import classNames from "classnames";
import MaterialIcon from "material-icons-react";
import helpers from "../../helpers";
import isEqual from "lodash/isEqual";

import { TYPE } from "./consts";
import "./styles.scss";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import mergeWith from "lodash/mergeWith";
import templates from "./components/Layout/templates";
import ReactDOMServer from "react-dom/server";
import AssetSelector from "../../components/AssetSelector";

function mapStateToProps(store) {
  return {
    ui: store.ui,
    assets: store.orm.AssetData,
    organization: store.db.organization,
    details: store.db.version.details
  };
}

class RuleBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAssetSelector: false,
    };
    this.onAssetReceive = this.onAssetReceive.bind(this);
    this.renderWidget = this.renderWidget.bind(this);

    const { locale } = this.props;
    this.imageWidgetAttr = {
      mimeType: "image/png, image/jpeg",
      locale,
    };
    this.imageTextWidgetAttr = {
      mimeType: "image/png, image/jpeg",
      locale,
      size: "[250]", // restrict maximum width, allow height to adjust accordingly
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { layout, modules } = this.props;

    if (prevProps.modules !== modules || !isEqual(layout, prevProps.layout)) {
      this.props.onUpdate({
        rule: { uuid: this.props.uuid, html: toHTML({ layout, modules }) },
      });
    }
  }

  toggleAssetSelector = (value) => {
    this.setState({ showAssetSelector: value });
  };

  onDragEnd = (result) => {
    const { layout, locale, onUpdate, uuid } = this.props;
    const { destination, draggableId, source } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case "droppable": {
        const modules = helpers.generic.reorderArray(
          layout,
          source.index,
          destination.index
        );

        onUpdate({
          rule: {
            uuid,
            modules,
          },
        });
        break;
      }

      case "widgets": {
        const module = createWidgetModule(draggableId, locale);
        const modules = Array.from(layout);
        modules.splice(destination.index, 0, module.uuid);

        onUpdate({
          module,
          rule: {
            uuid,
            modules,
          },
        });
        break;
      }

      default:
        break;
    }
  };

  renderWidget(module, index, onAssetsOpen, onHandleContent) {
    const { data, uuid } = module;
    const { type, ...attr } = data;

    switch (type) {
      case TYPE.TEXT:
        // TODO: define Slate schema and pass it in as an attribute
        return (
          <TextWidget
            key={uuid}
            uuid={uuid}
            index={index}
            {...attr}
            onHandleContent={onHandleContent}
            onAssetsOpen={onAssetsOpen}
          />
        );
      case TYPE.NOTE:
        return (
          <NoteWidget
            key={uuid}
            uuid={uuid}
            index={index}
            {...attr}
            onHandleContent={onHandleContent}
            onAssetsOpen={onAssetsOpen}
          />
        );
      case TYPE.IMAGE:
        return (
          <ImageWidget
            key={uuid}
            uuid={uuid}
            index={index}
            attr={this.imageWidgetAttr}
            {...attr}
            onHandleContent={onHandleContent}
            onAssetsOpen={onAssetsOpen}
          />
        );
      case TYPE.IMAGE_TEXT:
        return (
          <ImageTextWidget
            key={uuid}
            uuid={uuid}
            index={index}
            attr={this.imageTextWidgetAttr}
            {...attr}
            onHandleContent={onHandleContent}
            onAssetsOpen={onAssetsOpen}
          />
        );
      case TYPE.TEXT_IMAGE:
        return (
          <ImageTextWidget
            isReversed
            key={uuid}
            uuid={uuid}
            index={index}
            attr={this.imageTextWidgetAttr}
            {...attr}
            onHandleContent={onHandleContent}
            onAssetsOpen={onAssetsOpen}
          />
        );
      case TYPE.YOUTUBE:
        return (
          <YouTubeWidget
            key={uuid}
            uuid={uuid}
            index={index}
            {...attr}
            onHandleContent={onHandleContent}
          />
        );
      default:
        return;
    }
  }

  onHandleContent = (value) => {
    const { onUpdate } = this.props;
    const { uuid, ...data } = value;

    const moduleState = cloneDeep(this.props.modules[uuid]);
    const mergedModule = mergeWith({}, moduleState, { uuid, data });
    mergedModule.html = ReactDOMServer.renderToStaticMarkup(
      <div
        className="row"
        dangerouslySetInnerHTML={{ __html: templates(mergedModule) }}
      />
    );

    return onUpdate({
      module: mergedModule,
    });
  };

  onAssetsOpen = (attr, callback) => {
    this.callback = callback;
    this.props.showAsset({
      showSecondary: true,
      attr: attr,
      target: {},
    });
    this.toggleAssetSelector(true)
  };

  onAssetsClose = ()=>{
    this.toggleAssetSelector(false)
    this.props.onCancel()
  }

  onAssetReceive(asset) {
    this.callback(asset);
  }

  render() {
    const { layout, modules, onRemove, uuid, organization, locale, currentAsset } =
      this.props;
      
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className="grid grid_2cols rule-builder">
          <div>
            <AssetSelector
              visible={this.state.showAssetSelector}
              onClose={this.onAssetsClose}
              assetId={currentAsset}
              locale={locale}
              organizationId={organization.organizationId}
              onSelectAsset={(asset) => {
                this.onAssetReceive(asset);
                this.onAssetsClose();
              }}
            />
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={dropAreaClassNames(layout.length === 0)}
                  style={{ overflow: "auto" }}
                >
                  {layout.length === 0 && "Drag modules here..."}
                  {layout.map((module, i) => (
                    <Draggable
                      key={module}
                      draggableId={module}
                      index={parseInt(i, 10)}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="rule-builder__row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div className="rule-builder__handles">
                            <div
                              className="rule-builder__move"
                              {...provided.dragHandleProps}
                            >
                              <MaterialIcon icon="drag_handle" />
                            </div>
                            <div
                              className="rule-builder__delete"
                              onClick={() =>
                                onRemove({
                                  module,
                                  rule: { uuid },
                                })
                              }
                            >
                              <MaterialIcon
                                icon="delete_forever"
                                color="#ff1654"
                              />
                            </div>
                          </div>
                          {this.renderWidget(
                            modules[module],
                            i,
                            this.onAssetsOpen,
                            this.onHandleContent
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div>
            <div className="widget_tabs">
              <div className="widget_tabs widget_tab_active">
                <MaterialIcon icon="view_compact" color="#0090a1" />
              </div>
              Modules
            </div>
            <Droppable
              droppableId="widgets"
              isDropDisabled // we only drag widgets from this to "droppable"
            >
              {(provided) => (
                <div
                  className="grid box widgets"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {[
                    { name: "Text", icon: "subject", type: TYPE.TEXT },
                    {
                      name: "Image+Text",
                      icon: "art_track",
                      type: TYPE.IMAGE_TEXT,
                    },
                    {
                      name: "Text+Image",
                      icon: "art_track",
                      type: TYPE.TEXT_IMAGE,
                      isReversed: true,
                    },
                    { name: "Image", icon: "photo", type: TYPE.IMAGE },
                    { name: "Note", icon: "note", type: TYPE.NOTE },
                    {
                      name: "YouTube",
                      icon: "video_library",
                      type: TYPE.YOUTUBE,
                    },
                  ].map((props, i) => (
                    <Draggable
                      draggableId={props.type}
                      key={i}
                      index={parseInt(i, 10)}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Widget {...props} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    );
  }
}

export default connect(mapStateToProps)(RuleBuilder);

function dropAreaClassNames(empty) {
  return classNames({
    "rule-builder__content": true,
    "rule-builder__content_empty": empty,
  });
}
