import { actions } from '../consts';
import { getDefaultColor } from '../helpers/generic_helper';

const INIT_STATE = { type: actions.FILTER_ALL, color: '#ff9900', title: 'All rules' };

export default (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case actions.FILTER_ALL:
      return INIT_STATE;
    case actions.FILTER_UNCATEGORIZED:
      return { type: action.type, color: getDefaultColor(), title: 'Uncategorized'};
    case actions.FILTER_INPUT:
      return { type: action.type, color: '#008f9e', title: payload.title };
    case actions.FILTER_CATEGORY:
      return { ...payload, type: action.type }
    default:
      return state;
  }
}
