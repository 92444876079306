import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { Component } from "react";
import moment from "moment";
import { SmartInput, Input } from "../../components/Input";
import SortableTreeList from "../../components/SortableTreeList";
import { Menu, SmartMenu } from "../../components/Menu";
import Tag from "../../components/Tag";
import Modal from "../../components/Modal";
import Rule from "../../components/Rule";
import Button from "../../components/Button";
import { DropDown } from "../../components/DropDown";
import Spinner from "../../components/Spinner";
import InfoList from "../../components/InfoList";
import * as version from "../../actions/chosen_version_action";
import SearchProxyActions from "../../actions/SearchproxyAction";
import * as modal from "../../actions/modal_action";
import * as project from "../../actions/project_action";
import * as title from "../../actions/title_action";
import * as game from "../../actions/game_action";
import { projectsFilter } from "../../actions/project_action";
import { actions, imageSizes, limits } from "../../consts";
import isEmpty from "lodash/isEmpty";
import helpers from "../../helpers";
import filter_selector from "../../selectors/filter_selector";
import AssetSelector from "../../components/AssetSelector"
import "./styles.scss";
import { getStringFromTO } from "../../common/utils/i18nUtils";
import SortableList from "../../components/SortableList";
import { FILTER_CATEGORY } from "../../consts/action-types";
import Tabs from "../../components/Tabs";
import MaterialIcon from "material-icons-react";
import FaqsOverview from "../FaqsOverview";
import {
  getModalActionOne,
  getModalActionTwo,
  getActionCancelText,
  getActionOneText,
  getActionTwoText,
  getModalHeaderText,
  getModalContent,
} from "../../helpers/entities_helper";

import { getLocale } from "../../helpers/generic_helper";
import { defaultLocale, sortedLocaleList } from "../../helpers/LocaleList";

function onPreventUnload(event) {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = "";
}

class ProjectOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasNewRule: false,
      action: undefined,
      changeLocale: undefined,
      showAssetSelector: false,
      assetTarget: {},
    };

    this.action = {
      publish: "publish",
      reminder: "reminder",
      exit: "exit",
    };
  }

  componentDidMount() {
    this.checkLastEdited();
  }

  componentWillUnmount() {
    this.clearSaveReminder();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    function updateMenuRuleOrdering(context, nextProps) {
      helpers.entity.updateRuleOrder({
        menu:
          nextProps.filter.uuid &&
          nextProps.entities.menus[nextProps.filter.uuid],
        rules: nextProps.rules,
        method: context.props.updateVersionContent,
      });
    }
    updateMenuRuleOrdering(this, nextProps);
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextState.hasNewRule) {
      this.setState({
        hasNewRule: helpers.entity.sortNewRuleToTop(
          nextProps,
          this.state,
          nextState,
          this.props.updateVersionContent
        ),
      });
    }
  }

  componentDidUpdate(prevProps, prevState, Snapshot) {
    this.checkLastEdited();

    const prevProjectUuid = prevProps.details.projectUuid;
    const currentProjectUuid = this.props.details.projectUuid;
    const detailsChanged = prevProjectUuid !== currentProjectUuid;

    if (detailsChanged) {
      this.props.getProject(projectsFilter("uuid", currentProjectUuid));
    }

    const { projects, games, getSingleGame, setTitle } = this.props;
    const projectsChanged = projects !== prevProps.projects;

    if (projectsChanged) {
      const gameId = projects[0].game;
      getSingleGame({ gameId: gameId });
    }

    const gamesChanged = games !== prevProps.games;

    if (gamesChanged) {
      const gameTitle = games[0].title;
      setTitle({ subtitle: getStringFromTO(gameTitle) });
    }
  }

  toggleAssetSelector = (value) => {
    this.setState({ showAssetSelector: value });
  };

  updateAsset = ({ show, target }) => {
    this.toggleAssetSelector(show);
    this.setState({ assetTarget: target });
  }

  preventCloseAndReload() {
    window.addEventListener("beforeunload", onPreventUnload);
  }

  allowCloseAndReload() {
    window.removeEventListener("beforeunload", onPreventUnload);
  }

  clearSaveReminder() {
    if (this._saveReminderTimeout) clearTimeout(this._saveReminderTimeout);
    this._saveReminderTimeout = undefined;
  }

  showSaveReminderModal = () => {
    this.clearSaveReminder();
    this.setState({ action: this.action.reminder });
    this.props.showModal();
  };

  checkLastEdited() {
    const {
      details: { lastEdited, updated: lastSaved },
    } = this.props;
    const { action } = this.state;

    if (lastEdited && lastEdited > lastSaved) {
      this.preventCloseAndReload();

      if (!this._saveReminderTimeout && !action)
        this._saveReminderTimeout = setTimeout(
          this.showSaveReminderModal,
          10 * 60 * 1000
        );
    }
  }

  getLocale = () => {
    const {
      details: { locale },
    } = this.props;
    return getLocale(locale);
  };

  onCancel = () => {
    this.setState({ action: undefined });
    this.props.onCancel();
    this.toggleAssetSelector(false);
  };

  onFilterInputChange = (event) => {
    const { value } = event.target;
    const { applyFilter } = this.props;

    if (!value) {
      return applyFilter({ type: actions.FILTER_ALL });
    }

    return applyFilter({
      type: actions.FILTER_INPUT,
      payload: { title: value },
    });
  };

  onSelectColor = (color, categoryUuid) => {
    const { entities } = this.props;

    const categoryLookup = entities.menus;
    const parents = entities.parents;
    const categoryDetails = categoryLookup[categoryUuid];
    const rulesList = categoryDetails.ruleOrder;

    this.updateContent({
      menu: {
        uuid: categoryUuid,
        color: color,
      },
    });

    rulesList.forEach((ruleUuid) => {
      const primaryCategory = parents[ruleUuid][0];
      if (categoryDetails.uuid === primaryCategory) {
        this.updateContent({
          rule: {
            uuid: ruleUuid,
            color: color,
          },
        });
      }
    });
  };

  onAssetReceive = (asset) => {
    const { assetTarget } = this.state;

    if (assetTarget && assetTarget.uuid) {
      this.updateContent({
        [assetTarget.type]: {
          ...assetTarget,
          icon: {
            ...assetTarget.icon,
            ...asset,
          },
        },
      });
    }
  };

  onAssetsClick = () => {
    this.updateAsset({ show: true, target: {} });
  };

  onExitClick = () => {
    this.setState({ action: this.action.exit });
    this.props.showModal();
  };

  onSaveClick = (exitOnSave) => {
    const ruleOrder = this.props.rules;
    const rules = Object.keys(this.props.entities.rules).map(function (key, i) {
      return key;
    });

    this.clearSaveReminder();
    this.allowCloseAndReload();

    rules.sort(function (a, b) {
      return ruleOrder.indexOf(a) - ruleOrder.indexOf(b);
    });

    const { details, entities, menus, questions, palette } = this.props;

    if (!details.title || !details.version) {
      return;
    }

    details.updated = moment().format();

    this.props.saveVersionContent({
      ...details,
      entities,
      menus,
      questions,
      rules,
      palette,
    });

    this.postSearchTerms(entities, details);

    if (exitOnSave) {
      this.returnToOrgPortal();
    }
  };

  postSearchTerms(entities, details) {
    let questionData = [];
    let pageData = [];

    Object.keys(entities.questions).forEach((key) => {
      const question = entities.questions[key];
      questionData.push({
        uuid: question.uuid,
        type: "question",
        text: question.title.text,
      });
    });

    Object.keys(entities.rules).forEach((key) => {
      const rule = entities.rules[key];
      pageData.push({
        uuid: rule.uuid,
        type: "rule",
        text: `${rule.title.text} ${rule.html
          .replace(/\r/g, "\\\\r")
          .replace(/\n/g, "\\\\n")}`,
      });
    });

    this.props.searchProxyActions
      .POST_TERMS(questionData.concat(pageData), details.projectUuid, "dev")
      .catch((error) => {
        console.error("Error: Could not update questions in search proxy");
      });
  }

  onPublishClick = () => {
    this.setState({ action: this.action.publish });
    this.props.showModal();
  };

  onCreateMenu = () => {
    const { entities, palette, filter } = this.props;
    const locale = this.getLocale();

    let newPosition = 0;
    const selectedParent = filter.uuid ? filter.uuid : "";
    const list = Object.keys(entities.menus);

    list.forEach((menuUuid) => {
      if (entities.menus[menuUuid].parent === selectedParent) {
        newPosition++;
      }
    });

    helpers.entity.createMenu({
      name: helpers.entity.nextNameId(entities.menus, { prefix: "M" }),
      parent: filter.type === FILTER_CATEGORY ? filter.uuid : "",
      method: this.updateContent,
      order: newPosition,
      palette,
      locale,
    });
  };

  onCreateRule = () => {
    const { filter, entities, palette } = this.props;
    const locale = this.getLocale();

    helpers.entity.createRule({
      name: helpers.entity.nextNameId(entities.rules, { prefix: "R" }),
      filter: filter,
      method: this.updateContent,
      palette,
      locale,
    });
    this.setState({ hasNewRule: true });
  };

  onLocaleChangeSet = (changeLocale) => {
    this.setState({ changeLocale });
  };

  onLocaleChangeCancel = () => {
    this.onLocaleChangeSet();
  };

  onLocaleChangeAccept = () => {
    const { changeLocale } = this.state;

    this.onLocaleChangeSet();
    this.updateContent({ details: { locale: [changeLocale] } });
  };

  updateContent = (params) => {
    this.preventCloseAndReload();
    this.props.updateVersionContent(params);
  };

  navigateToEditor = (url) => {
    this.allowCloseAndReload();
    this.props.history.push(url);
  };

  returnToOrgPortal = () => {
    this.allowCloseAndReload();

    const baseURL =
      window.__SERVER_DATA__ && window.__SERVER_DATA__.REACT_APP_ORG_PORTAL
        ? window.__SERVER_DATA__.REACT_APP_ORG_PORTAL
        : process.env.REACT_APP_ORG_PORTAL;
    window.location.href = `${baseURL}/${this.props.match.params.orgId}/games`;
  };

  getUnassignedRuleCount() {
    const { entities } = this.props;
    const { parents, rules } = entities;

    const childless = Object.keys(parents).filter(
      (children) => parents[children].length === 0
    );

    let unassignedRuleCount = 0;
    Object.keys(rules).forEach((ruleId) => {
      if (childless.includes(rules[ruleId].parents)) {
        unassignedRuleCount++;
      }
    });

    return unassignedRuleCount;
  }

  renderTopMenu = () => {
    const { details } = this.props;
    return (
      <div className="info-string">
        <InfoList {...details} />
        <div className="info-string__buttons">
          <Button onClick={this.onAssetsClick}>Assets</Button>
          <Button
            inactive={!details.title || !details.version}
            onClick={() => this.onSaveClick(false)}
          >
            Save Draft
          </Button>
          <Button secondary onClick={this.onPublishClick}>
            Publish Rules
          </Button>
          <Button close={true} onClick={this.onExitClick}>
            <MaterialIcon icon="clear" size={31} color="#fff" />
          </Button>
        </div>
      </div>
    );
  };

  renderTabs = () => {
    const locale = this.getLocale();

    return (
      <div className="ruleset">
        <div className="info-string__container">{this.renderTopMenu()}</div>
        <div className="ruleset__container">
          <Tabs>
            <div tab="Rules">{this.renderRules()}</div>
            <div tab="Faqs">
              <FaqsOverview
                {...this.props}
                locale={locale}
                onFaqEditClick={this.navigateToEditor}
              />
            </div>
            <div tab="Settings">{this.renderSettings()}</div>
          </Tabs>
        </div>
      </div>
    );
  };

  renderSettings = () => {
    const { details } = this.props;
    const { changeLocale } = this.state;
    const locale = this.getLocale();

    return (
      <div className="wrap1140 content">
        <div className="settings__item">
          <span>Version name: </span>
          <SmartInput
            table="details"
            field="title"
            placeholder="Enter Title"
            initValue={details.title}
            onBlur={this.updateContent}
          />
        </div>
        <div className="settings__item">
          <span>Internal Ref: </span>
          <SmartInput
            table="details"
            field="version"
            placeholder="Enter ref"
            initValue={details.version}
            onBlur={this.updateContent}
          />
        </div>
        <div className="settings__item">
          <span>Default Language: </span>
        </div>
        <div className="settings__item">
          <span>
            (This sets the default language for texts and assets in the rules.
            The language <strong>CAN ONLY BE CHANGED ONCE!</strong>)
          </span>
        </div>
        <Modal
          show={!!changeLocale}
          header="Change the language?"
          children={<div>The language can only be changed once!</div>}
          actionOneText="Change"
          cancelText="Cancel"
          onActionOne={this.onLocaleChangeAccept}
          onCancel={this.onLocaleChangeCancel}
        />
        <div className="settings__item">
          <DropDown
            disabled={locale !== defaultLocale}
            onChange={(event) => this.onLocaleChangeSet(event.target.value)}
            options={[...sortedLocaleList]}
            value={locale}
          />
        </div>
      </div>
    );
  };

  renderRules = () => {
    const {
      entities,
      questions,
      filter,
      rules,
      palette,
      processing,
      removeVersionContent,
      onAttempt,
      applyFilter,
      match,
    } = this.props;
    const { parents } = entities;

    return (
      <React.Fragment>
        <div className="wrap1140 content">
          <div className="grid grid_2cols grid_gap_XL">
            <div className="grid__sidebar">
              <div className="grid-list grid_gap_L">
                <div className="ruleset__list-header">
                  <h3>Categories</h3>
                  <Button onClick={this.onCreateMenu}>Add category</Button>
                </div>
                <div className="grid-list">
                  <Menu
                    numberOfRules={Object.keys(entities.rules).length}
                    show_all="true"
                    onFilter={() => applyFilter({ type: actions.FILTER_ALL })}
                  >
                    SHOW ALL RULES
                  </Menu>
                  <Menu
                    icon="help"
                    numberOfRules={this.getUnassignedRuleCount()}
                    uncategorized
                    onFilter={() =>
                      applyFilter({ type: actions.FILTER_UNCATEGORIZED })
                    }
                  >
                    Unassigned Rules
                  </Menu>
                </div>
                <SortableTreeList
                  entities={entities.menus}
                  component={SmartMenu}
                  processing={processing}
                  palette={palette}
                  maxDepth={limits.MAX_CAT_DEPTH}
                  parents={parents}
                  onDelete={removeVersionContent}
                  onAttempt={onAttempt}
                  onUpdate={this.updateContent}
                  onCancel={this.onCancel}
                  onFilter={applyFilter}
                  onSelectColor={this.onSelectColor}
                  showAsset={this.updateAsset}
                />
              </div>
            </div>
            <div className="grid__main">
              <div className="grid-list grid_gap_L">
                <div className="ruleset__list-header">
                  <div className="ruleset__list-header">
                    <h3>Rules</h3>
                    <Tag
                      className="ruleset__list-tag"
                      color={palette[filter.color] || filter.color}
                      onClear={() => applyFilter({ type: actions.FILTER_ALL })}
                      sorted={filter.type !== actions.FILTER_ALL}
                    >
                      {getStringFromTO(filter.title)}
                    </Tag>
                  </div>
                  <Button onClick={this.onCreateRule}>Add rule</Button>
                </div>
                <div className="ruleset__filter">
                  <Input
                    filter
                    placeholder="Filter rules"
                    onChange={this.onFilterInputChange}
                  />
                </div>
                <SortableList
                  type={"rules"}
                  isDragDisabled={
                    filter ? filter.type === "filter::UNCATEGORIZED" : false
                  }
                  palette={palette}
                  collection={helpers.entity.sortRules(
                    filter.uuid && this.props.entities.menus[filter.uuid],
                    rules
                  )}
                  target={filter.uuid && this.props.entities.menus[filter.uuid]}
                  processing={processing}
                  entities={entities.rules}
                  parents={entities.parents}
                  questions={questions}
                  filter={filter}
                  questions_entities={entities.questions}
                  modules={entities.modules}
                  component={Rule}
                  params={match.params}
                  onEditClick={this.navigateToEditor}
                  onDragEnd={this.onRuleDragEnd}
                  onAttempt={onAttempt}
                  onDelete={removeVersionContent}
                  onUpdate={this.updateContent}
                  showAsset={this.updateAsset}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { modal, onConfirm, removeVersionContent } = this.props;
    const { action, assetTarget } = this.state;
    const locale = this.getLocale();

    // TODO: Update the Cover Image size when we know what it needs to be
    let imageSizeString = imageSizes.MENU_CATEGORY_ICON_SIZE;
    let currentAsset = assetTarget && assetTarget.icon && assetTarget.icon.assetId;

    const attr = {
      mimeType: "image/png, image/jpeg",
      locale,
      aspectRatio: "1:1",
      size: imageSizeString,
    };

    return this.props.fetching ? (
      <Spinner />
    ) : (
      <React.Fragment>
        {this.renderTabs()}

        <AssetSelector
          assetId={currentAsset}
          locale={attr.locale}
          onClose={this.onCancel}
          onSelectAsset={(asset) => {
            this.onAssetReceive(asset)
            this.onCancel();
          }}
          visible={this.state.showAssetSelector}
        />
        <Modal
          show={modal.show}
          header={getModalHeaderText(action, modal.type)}
          children={getModalContent(action)}
          onActionOne={getModalActionOne(
            action,
            this.onCancel,
            () => onConfirm(removeVersionContent),
            this.onSaveClick
          )}
          onActionTwo={getModalActionTwo(action, () =>
            this.returnToOrgPortal()
          )}
          onCancel={this.onCancel}
          actionOneText={getActionOneText(action)}
          actionTwoText={getActionTwoText(action)}
          cancelText={getActionCancelText(action)}
          hideCancel={[this.action.publish, this.action.reminder].includes(
            action
          )}
        />
      </React.Fragment>
    );
  }
}

class ProjectOverviewContainer extends Component {
  componentDidMount() {
    const { params } = this.props.match;
    const { getVersionContent, details } = this.props;
    if (!isEmpty(details)) return;
    return getVersionContent(params);
  }

  render() {
    return <ProjectOverview {...this.props} />;
  }
}

const mapStateToProps = ({ db, ui, orm }) => {
  const { entities, details, menus, rules, questions, palette } = db.version;
  
  return {
    processing: ui.processing,
    modal: ui.modal,
    assets: orm.AssetData,
    fetching: ui.fetching,
    filter: ui.filter,
    entities,
    details,
    menus,
    questions,
    organization: db.organization,
    projects: db.projects,
    title: ui.title,
    games: db.games,
    rules: filter_selector({
      state: rules,
      entities: entities.rules,
      parents: entities.parents,
      filter: ui.filter,
    }),
    palette,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getVersionContent: (params) => dispatch(version.get(params)),
    getProject: (params) => dispatch(project.get(params)),
    updateVersionContent: (params) => dispatch(version.update(params)),
    removeVersionContent: (params) => dispatch(version.remove(params)),
    saveVersionContent: (params) => dispatch(version.put(params)),
    searchProxyActions: SearchProxyActions.bindActions(dispatch),
    showModal: () => dispatch(modal.show()),
    applyFilter: (params) => dispatch(version.filter(params)),
    onAttempt: (params) => dispatch(version.attempt(params)),
    onConfirm: (callback) => dispatch(version.confirm(callback)),
    onCancel: () => dispatch(version.cancel()),
    setTitle: (params) => dispatch(title.setTitle(params)),
    getSingleGame: (params) => dispatch(game.getSingleGame(params)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectOverviewContainer)
);
