import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {defaultLocale} from '../../../../helpers/LocaleList';

export class IconWidgetAttributes {

  static attributeLookup = {
	  id: 'data-id',
	  type: 'data-type',
	  height: 'data-height',
	  url: 'data-url',
	  attr: 'data-attr'
  };

  static createObject(id, type, height, url, attr, src) {
	  let attributes = IconWidgetAttributes.attributeLookup;
	  let data = {};
	  data[attributes.id] = id || '';
	  data[attributes.type] = type || 'IconWidget';
	  data[attributes.height] = height || '60';
	  data[attributes.url] = url || 'https://dummyimage.com/550x250/cccccc/000000&text=Click+to+set+image';
	  data[attributes.attr] = attr || '';

	  if (src !== undefined) {
	    data[src] = src;
	  }

	  return data;
  }

  static createPropertyArray(id, type, height, url, attr) {

	  let createObject = IconWidgetAttributes.createObject;
	  let attribute = IconWidgetAttributes.attributeLookup;

	  let dataObject = createObject(id, type, height, url, attr);
	  let dataArray = [];

	  dataArray[attribute.id] = dataObject[attribute.id];
	  dataArray[attribute.type] = dataObject[attribute.type];
	  dataArray[attribute.height] = dataObject[attribute.height];
	  dataArray[attribute.url] = dataObject[attribute.url];
	  dataArray[attribute.attr] = dataObject[attribute.attr];

	  return dataArray;
  }
}

export default class IconWidget extends Component {
  static propTypes = {
    locale: PropTypes.string,
  };

  static defaultProps = {
    locale: defaultLocale,
  };

  componentDidMount() {
    this.height = this.inlineIcon.clientHeight;
  }

  onClick = () => {
	  const {locale} = this.props;
	  let attr = {
	    mimeType: 'image/png, image/jpeg',
	    locale,
	    size: '[' + this.height + ',' + this.height + ']'
	  };
	  this.props.onAssetsOpen(attr, (asset) => this.onAssetReceive(asset));
  };

  onAssetReceive(asset) {
	  this.id = asset.uuid;
	  this.props.editor.change(change => {
	    let createObject = IconWidgetAttributes.createObject;
	    const data = createObject(
	      asset.uuid,
	      'IconWidget',
	      this.height,
	      asset.download,
	      JSON.stringify(asset.attr)
	    );
	    const nodeKey = this.props['data-key'];
	    change.setNodeByKey(nodeKey, {data: data});
	  })
  }

  render() {
	  let attributes = IconWidgetAttributes.attributeLookup;
	  return (
	    <img className="rule-builder__icon"
			    alt=""
	      ref={(inlineIcon) => this.inlineIcon = inlineIcon}
	      data-id={this.id}
	      data-type={this.props[attributes.type]}
	      data-height={this.height}
	      data-url={this.props[attributes.url]}
	      data-attr={this.props[attributes.attr]}
	      style={{height: '1em'}}
	      onClick={this.onClick}
	      src={this.props[attributes.url]} />
	  )
  }
}
