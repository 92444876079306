import { actions } from '../consts';
import helpers from '../helpers';

export default (state = [], action) => {
  switch (action.type) {
    case actions.SET_VERSIONS: 
      const { meta } = action;
      return (meta && meta.table === 'versions') 
        ? helpers.generic.fromCollectionToObject(action.payload, 'uuid') 
        : state;
    default:
      return state;
  }
}