import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AssetExplorer } from "@dized/ui";

import AssetActions from "../../common/actions/AssetActions";
import AssetDataActions from "../../common/actions/AssetDataActions";
import FolderActions from "../../common/actions/FolderActions";
import arraySelector from "../../common/selectors/arraySelector";

class AssetSelector extends Component {
  static propTypes = {
    assetId: PropTypes.string,
    locale: PropTypes.string.isRequired,
    organizationId: PropTypes.string,
    onSelectAsset: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    folders: PropTypes.array,
    assets: PropTypes.array,
    assetData: PropTypes.array,
    assetDataIdMap: PropTypes.object,
    additionalFilters: PropTypes.array,
    typeFilter: PropTypes.string,
    FETCH_FOLDERS: PropTypes.func,
    FETCH_ASSETS: PropTypes.func,
    UPLOAD_ASSET: PropTypes.func,
    FETCH_ASSET_DATA_BY_FOLDER: PropTypes.func,
    CREATE_FOLDER: PropTypes.func,
    DELETE_FOLDER: PropTypes.func,
    DELETE_ASSET: PropTypes.func,
    UPDATE_FOLDER: PropTypes.func,
    UPDATE_ASSET: PropTypes.func,
  };

  static defaultProps = {
    additionalFilters: [],
    typeFilter: "image",
  };

  constructor(props) {
    super(props);

    const { locale } = this.props;
    this.state = {
      assetConstraints: {
        mimeType: "image/png, image/jpeg",
        locale: locale ? locale : "en-US",
        aspectRatio: "1:1",
        size: "[200,200]",
      },
      currentFolder: "",
      isInitialFetchDone: false,
      isAssetDataByFolderFetchDone: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { locale, visible } = this.props;
    const { currentFolder } = this.state;

    if (!prevProps.visible && visible) {
      this.initialFetch();
    }
    if (prevState.currentFolder !== currentFolder) {
      this.fetchMissingAssetData();
    }
    if (prevProps.locale !== locale) {
      const { assetConstraints } = this.state;
      this.setState({
        assetConstraints: {
          ...assetConstraints,
          locale: locale,
        },
      });
    }
  }

  /**
   * Extends React Component lifecycle API with a promisified version of setState()
   *
   * @param {object} newState - new component state passed to this.setState()
   * @returns {Promise}       resolves when component state has been updated
   */
  setStatePromise(newState) {
    return new Promise((resolve) => this.setState(newState, resolve));
  }

  initialFetch() {
    const { isInitialFetchDone } = this.state;

    if (!isInitialFetchDone) {
      const { organizationId, FETCH_ASSETS, FETCH_FOLDERS } = this.props;

      return Promise.resolve()
        .then(() =>
          Promise.all([
            FETCH_ASSETS(),
            FETCH_FOLDERS({ filter: { where: { organizationId } } }),
          ])
        )
        .then(() => {
          const { folders } = this.props;
          const { uuid } = folders.find(
            (folder) => folder.parentId === null
          ) || { uuid: "" };
          return this.setStatePromise({
            currentFolder: uuid,
            isInitialFetchDone: true,
            isAssetDataByFolderFetchDone: true,
          });
        });
    }
  }

  fetchMissingAssetData() {
    const { assetDataIdMap, FETCH_ASSET_DATA_BY_FOLDER } = this.props;

    if (this.getFiles().find((file) => !assetDataIdMap[file.id])) {
      return Promise.resolve()
        .then(() => this.setStatePromise({
          isAssetDataByFolderFetchDone: false,
        }))
        .then(() => {
          const { currentFolder } = this.state;
          return FETCH_ASSET_DATA_BY_FOLDER(currentFolder);
        })
        .then(() => this.setStatePromise({
          isAssetDataByFolderFetchDone: true,
        }));
    }
  }

  filterInCurrentFolder(data = [], parentAttr) {
    const { currentFolder } = this.state;

    return data.filter((x) => x[parentAttr] === currentFolder);
  }

  getFolders() {
    const { folders } = this.props;

    return this.filterInCurrentFolder(folders, "parentId").map((folder) => ({
      ...folder,
      id: folder.uuid, // Explorer needs id, not uuid
    }));
  }

  getAssetDataForExplorer(id) {
    const { assetDataIdMap } = this.props;
    const assetData = assetDataIdMap[id];
    if (!assetData) return;

    const { assetId, locale, modified, type } = assetData;
    const params = encodeURI("?max=true&size=[100,100]&as=jpeg");
    // TODO: Use some environment variables or similar to get the path
    const src = `/api/v1/assets/asset/${assetId}/${locale}/download${params}`;

    return {
      mimeType: type,
      // override "modified" from asset as that doesn't get updated when asset is replaced
      modified,
      src,
    };
  }

  getFiles() {
    const { assets } = this.props;

    return this.filterInCurrentFolder(assets, "folder").map((asset) => ({
      ...asset,
      ...this.getAssetDataForExplorer(asset.id),
    }));
  }

  getParentFolder() {
    const { folders } = this.props;
    const { currentFolder } = this.state;

    return currentFolder
      ? (folders.find((f) => f.uuid === currentFolder) || {}).parentId || null
      : null;
  }

  getAssets() {
    const { currentFolder } = this.state;

    return {
      currentFolder: currentFolder,
      parentFolder: this.getParentFolder(),
      folders: this.getFolders(),
      files: this.getFiles(),
    };
  }

  uploadAsset(data, currentFolder, locale) {
    const { UPLOAD_ASSET } = this.props;

    UPLOAD_ASSET(data, currentFolder, locale);
  }

  createFolder(name) {
    const { organizationId, CREATE_FOLDER } = this.props;
    const { currentFolder } = this.state;

    CREATE_FOLDER({
      name,
      organizationId,
      parent: currentFolder,
      parentId: currentFolder,
    });
  }

  deleteAsset(fileChangeInfo) {
    const { DELETE_FOLDER, DELETE_ASSET } = this.props;
    const { id, type } = fileChangeInfo;
    if (type === "folder") {
      DELETE_FOLDER(id);
    } else {
      DELETE_ASSET(id);
    }
  }

  assetRename(fileChangeInfo) {
    const { UPDATE_FOLDER, UPDATE_ASSET } = this.props;
    const { id, type, newName } = fileChangeInfo;
    if (type === "folders") {
      UPDATE_FOLDER(id, { name: newName });
    } else {
      UPDATE_ASSET(id, { name: newName });
    }
  }

  getPath(uuid) {
    const { folders } = this.props;
    let path = [];
    let folder = folders.find((f) => f.uuid === uuid);

    while (folder) {
      path.push(folder.uuid);
      folder = folders.find((f) => f.uuid === (folder.parentId || ""));
    }

    path = path.reverse();
    return "/" + path.join("/");
  }

  setCurrentFolder(uuid) {
    this.setState({ currentFolder: uuid });
  }

  onSelectAsset = (asset) => {
    const { assetData, onSelectAsset } = this.props;
    const { attr, id } = asset;
    const data = assetData.find((aData) => aData.assetId === id);
    if (!data) return;

    const { locale } = data;
    onSelectAsset({
      _type: "ao1",
      assetId: id,
      attr: {},
      context: {},
      // TODO: Use some environment variables or similar to get the path
      url: `/api/v1/assets/asset/${id}/${locale}/download`,
      _meta: { ...attr },
    });
  };

  render() {
    const { additionalFilters, assetId, onClose, typeFilter, visible } =
      this.props;
    const { assetConstraints, isInitialFetchDone, isAssetDataByFolderFetchDone } = this.state;
    const showLoadingIndicator = !(isInitialFetchDone && isAssetDataByFolderFetchDone);

    return (
      <AssetExplorer
        visible={visible}
        showLoadingIndicator={showLoadingIndicator}
        onClose={onClose}
        onAssetSelected={this.onSelectAsset}
        onAssetUpload={(data, currentFolder, locale, filename) =>
          this.uploadAsset(data, currentFolder, locale, filename)
        }
        onAssetRename={(fileChangeInfo) => this.assetRename(fileChangeInfo)}
        onCreateFolder={(folderName) => this.createFolder(folderName)}
        onAssetDelete={(fileChangeInfo) => this.deleteAsset(fileChangeInfo)}
        onNavigateToFolder={(id) => this.setCurrentFolder(id)}
        onAssetUploadComplete={() => {}}
        assetConstraints={assetConstraints}
        assets={this.getAssets()}
        additionalFilters={additionalFilters}
        selectedAssetId={assetId}
        typeFilter={typeFilter}
      />
    );
  }
}
function mapStateToProps(store) {
  const assetData = arraySelector("AssetData")(store);
  return {
    folders: arraySelector("Folder")(store),
    assets: arraySelector("Asset")(store),
    assetData,
    assetDataIdMap: assetData.reduce((a, c) => ({ ...a, [c.assetId]: c }), {}),
    organizationId: store.db.organization.organizationId,
  };
}

// mapDispatchToProps function
function mapDispatchToProps(dispatch) {
  return {
    ...FolderActions.bindActions(dispatch),
    ...AssetActions.bindActions(dispatch),
    ...AssetDataActions.bindActions(dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetSelector);
