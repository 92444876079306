import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Tab extends Component {

  static propTypes = {
    isActive: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: 'tab_item',
    activeTab: ' tab_active',
    disabledTab: ' tab_disabled'
  };

  onClick = () => {
    const {tab, enabled, onClick} = this.props;
    if (enabled.toString() === 'true') {
      onClick(tab);
    }
  };

  render() {
    const {isActive, tab, enabled} = this.props;
    return (
      <li className={isActive === tab
        ? this.props.className + this.props.activeTab
        : enabled.toString() === 'true'
          ? this.props.className
          : this.props.className + this.props.disabledTab}
      onClick={this.onClick}>
        {tab}
      </li>
    );
  }
}

export default Tab;
