import ReactDOMServer from 'react-dom/server';
import React from 'react';
import {head} from '../../../../common/rulesHelpers/htmlLayout';
import templates from './templates';

export default (props) => {
  return props.layout.length
    ? (`
        <html>
        ${head()}
          ${ReactDOMServer.renderToStaticMarkup(
        bodyWithContent(props)
      )}
        </html>
      `)
    : '';
}

export const bodyWithContent = (props) => {
  return (
    <body>
      <div className="layout-container" onMouseDown="DragScroller.StartScroll"
        onMouseMove="DragScroller.ScrollNow">
        {props.layout.map(uuid => {
          const module = props.modules[uuid];
          return <div key={uuid} className="row"
            dangerouslySetInnerHTML={{__html: templates(module)}}/>
        })}
      </div>
    </body>
  )
};

