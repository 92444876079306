import { schema } from 'normalizr';

export const chosen_version_schema = () => {
  const rule = new schema.Entity('rules', { 
    modules: [new schema.Entity('modules', {}, { idAttribute: 'uuid' })],
    parents:   new schema.Entity('parents', {}, { 
      idAttribute: (value, parent) => parent.uuid,
      processStrategy: (value, parent) => value
    })
  }, { 
    idAttribute: 'uuid'
  });

  const menu = new schema.Entity('menus', {
    parents:  new schema.Entity('parents', {}, { 
      idAttribute: (value, parent) => parent.uuid,
      processStrategy: (value, parent) => value
    })
  }, { 
    idAttribute: 'uuid' 
  });

  const questions = new schema.Entity('questions', {}, { 
    idAttribute: 'uuid' 
  });

  return { rules: [rule], menus: [menu], questions: [questions] };
}