import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';
import moment from 'moment';

import {defaultLocale, getLocaleName} from './LocaleList';

export function fromCollectionToObject(collection, field) {
  return keyBy(collection, (key) => key[field])
}

export function randomValue(array) {
  return array[Math.floor(Math.random() * array.length)];
}

export function reorderArray(list, startIndex, endIndex) {
  const array = Array.from(list),
    [removed] = array.splice(startIndex, 1);
  array.splice(endIndex, 0, removed);
  return array;
}

export function objectToArray(object) {
  return Object.keys(object).map(key => {
    return object[key];
  });
}

export function parseDate(date) {
  if (!date) return;
  return moment(date).format('DD MMMM YYYY');
}

export function parseFriendlyTime(date) {
  if (!date) return;
  return moment(date).fromNow();
}

export const getLocale = (locales = []) => {
  if (locales.length === 0) return defaultLocale;
  return locales[0];
};

export function parseLang(locales) {
  return getLocaleName(getLocale(locales));
}

export function replaceArrayValue(oldValue, newValue) {
  if (isArray(oldValue)) {
    return newValue;
  }
}

export function setBackground(color) {
  if (color && color !== getDefaultColor()) {
    return {
      backgroundColor: color
    }
  }
  return {
    backgroundColor: getDefaultColor(),
    border: '1px dashed #fff',
    boxShadow: 'none'
  }
}

export function getDefaultColor() {
  // TODO: Get this value from the css variables - once refactored into @dized/ui
  return '#9e9e9e';
}

export function compareArrays(_arr1, _arr2) {

  if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length)
    return false;

  let arr1 = _arr1.concat().sort();
  let arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {

    if (arr1[i] !== arr2[i])
      return false;

  }
  return true;
}

export function mergeAssignArray(objValue, srcValue) {
  if (isArray(objValue)) {
    return srcValue;
  }
}
