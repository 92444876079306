import React, {Component} from 'react';
import {Editor} from 'slate-react';
import ReactDOM from 'react-dom';
import Html from 'slate-html-serializer';
import {BLOCK, MARK} from '../../consts';
import IconWidget, {IconWidgetAttributes} from '../IconWidget';

const rules = [
  {
    deserialize(el, next) {
      const type = BLOCK[el.tagName];
      if (type) {
        return {
          object: 'block',
          type: type,
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      const isBlock = obj.object === 'block';
      if (!isBlock) return;

      switch (obj.type) {
        case BLOCK.P:
          return <p>{children}</p>
        case BLOCK.NOTE_TITLE:
          return <div className="note__header">{children}</div>
        case BLOCK.NOTE_TEXT:
          return <p>{children}</p>
        case BLOCK.H1:
          return <h1>{children}</h1>
        case BLOCK.H2:
          return <h2>{children}</h2>
        case BLOCK.H3:
          return <h3>{children}</h3>
        default:
          return;
      }
    }
  },
  {
    deserialize(el, next) {
      const {childNodes, className, tagName} = el;
      let type = MARK[tagName];

      // special case handling for spoiler HTML markup
      if (!type && (tagName === 'SPAN') && (className === 'spoiler'))
        type = MARK.SPOILER;

      if (type) {
        return {
          object: 'mark',
          type,
          nodes: next(childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case MARK.SPOILER:
            // NOTE: tooltip needs to be kept in-sync with rules-next implementation
            return <span className="spoiler" title="click to reveal">{children}</span>;
          case MARK.STRONG:
            return <strong>{children}</strong>
          case MARK.EM:
            return <em>{children}</em>
          case MARK.U:
            return <u>{children}</u>
          default:
            return;
        }
      }
    }
  },
  {
    deserialize(el, next) {
      if (el.tagName === 'IMG') {

        let createObject = IconWidgetAttributes.createObject;
        let attributes = IconWidgetAttributes.attributeLookup;

        return {
          object: 'inline',
          isVoid: true,
          type: 'inline-image',
          data: createObject(
            el.getAttribute(attributes.id),
            el.getAttribute(attributes.type),
            el.getAttribute(attributes.height),
            el.getAttribute(attributes.url),
            el.getAttribute(attributes.attr),
            el.getAttribute('src')
          ),
          nodes: next(el.childNodes),
        }
      }
    },
    serialize(obj, children) {
      if (obj.type === BLOCK.II) {

        let attributes = IconWidgetAttributes.attributeLookup;

        return (
          <img src={obj.data.get(attributes.url)}
            data-id={obj.data.get(attributes.id)}
            data-type={obj.data.get(attributes.type)}
            data-height={obj.data.get(attributes.height)}
            data-url={obj.data.get(attributes.url)}
            data-attr={obj.data.get(attributes.attr)}
            alt=""
          />
        );
      }
    }
  }
];

const html = new Html({rules});

export default class TextWidget extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: html.deserialize(props.data.text),
    };
  }

  onChange = ({value}) => {
    if (value.document !== this.state.value.document) {
      this.props.onHandleContent({
        uuid: this.props.uuid,
        data: {text: html.serialize(value)},
        focused: false,
      });
    }
    this.setState({value});
  };

  onBlur = () => {
    this.setState({focused: false});
  };

  onFocus = () => {
    this.setState({focused: true})
  };

  renderMark = props => {
    const {children, mark: {type}} = props;

    switch (type) {
      case MARK.SPOILER:
        return <span className="spoiler">{children}</span>;
      case MARK.STRONG:
        return <strong>{children}</strong>
      case MARK.EM:
        return <em>{children}</em>
      case MARK.U:
        return <u>{children}</u>
      default:
        return;
    }
  };

  renderNode = props => {
    const {data: {locale}} = this.props;
    const {attributes, children, node} = props;
    switch (node.type) {
      case BLOCK.NOTE_TITLE:
        return <div className="note__header" {...attributes}>{children}</div>;
      case BLOCK.NOTE_TEXT:
        return <p {...attributes}>{children}</p>;
      case BLOCK.P:
        return <p {...attributes}>{children}</p>;
      case BLOCK.H1:
        return <h1 {...attributes}>{children}</h1>;
      case BLOCK.H2:
        return <h2 {...attributes}>{children}</h2>;
      case BLOCK.H3:
        return <h3 {...attributes}>{children}</h3>;
      case BLOCK.II:

        let createWidgetProps = IconWidgetAttributes.createPropertyArray;
        let attributesLookup = IconWidgetAttributes.attributeLookup;

        let derivedAttributes = createWidgetProps(
          node.data.get(attributesLookup.id),
          node.data.get(attributesLookup.type),
          node.data.get(attributesLookup.height),
          node.data.get(attributesLookup.url),
          node.data.get(attributesLookup.attr)
        );

        console.log('LOCALE', locale, this.props);

        return (
          <IconWidget
            editor={props.editor}
            attr={this.props.attr}
            {...attributes}
            {...derivedAttributes}
            onAssetReceive={this.props.onAssetReceive}
            onAssetsOpen={this.props.onAssetsOpen}
            locale={locale}
          />
        );

      default:
        return;
    }
  };

  componentDidMount = () => {
    this.updateMenu();
  };

  componentDidUpdate = () => {
    this.updateMenu();
  };

  updateMenu = () => {
    const menu = this.menu;
    if (!menu) return;
    if (!this.state.focused) {
      menu.removeAttribute('style');
      return;
    }

    // Make menu stay
    const rect = ReactDOM
      .findDOMNode(this).parentNode
      .getBoundingClientRect();
    menu.style.opacity = 1;
    menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight + 5}px `;
    menu.style.left = `${rect.left}px`;
  };

  menuRef = menu => {
    this.menu = menu;
  };

  render() {
    const {value} = this.state;
    const {document} = value;

    return (
      <React.Fragment>
        <Editor
          className={`widget-text ${document.isEmpty ? 'widget-text-empty' : ''}`}
          value={value}
          onChange={this.onChange}
          renderMark={this.renderMark}
          schema={this.props.schema}
          placeholder="Add your rule text here..."
          renderNode={this.renderNode}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          style={{cursor: 'text'}}
          /*
           * Browser spellcheck correction modifies rendered content without
           * updating the internal editor state. This causes Editor to crash
           * when user tries to modify the style of the corrected text. I
           * guess for that exact reason newer versions of slate-react have
           * this feature disabled by default.
           */
          spellCheck={false}
          autoCorrect={false} // non-standard Safari feature
        />
        <Menu
          menuRef={this.menuRef}
          value={value}
          onChange={this.onChange} />
      </React.Fragment>
    )
  }
}

class Menu extends Component {

  hasMark(type) {
    const {value} = this.props;
    return value.activeMarks.some(mark => mark.type === type);
  }

  hasBlock(type) {
    const {value} = this.props;
    return value.blocks.some(node => node.type === type);
  }

  onClickMark(event, type) {
    event.preventDefault();
    const {value, onChange} = this.props;
    const change = value.change().toggleMark(type);
    onChange(change);
  }

  onClickBlock = (event, type) => {
    event.preventDefault();
    const {value, onChange} = this.props;
    const change = value.change();
    const isActive = this.hasBlock(type);
    change.setBlocks(isActive ? BLOCK.P : type);
    onChange(change);
  };

  onClickIcon = (event, type) => {
    event.preventDefault();
    const {value, onChange} = this.props;
    const change = value.change();
    let createWidgetProps = IconWidgetAttributes.createPropertyArray;

    change.insertInline({
      type: BLOCK.II,
      isVoid: true,
      data: createWidgetProps(),
    }).collapseToStartOfNextText()
      .focus();
    onChange(change);
  };

  renderMarkButton(type, icon) {
    const isActive = this.hasMark(type);
    const onMouseDown = event => this.onClickMark(event, type);
    return (
      <span className="hover-menu-button" onMouseDown={onMouseDown} data-active={isActive}>
        <span>{icon}</span>
      </span>
    );
  }

  renderIconButton() {
    const onMouseDown = event => this.onClickIcon(event);
    return (
      <span className="hover-menu-button" onMouseDown={onMouseDown}>
        <span> icon </span>
      </span>
    )
  }

  renderBlockButton = (type, icon) => {
    const isActive = this.hasBlock(type);
    const onMouseDown = event => this.onClickBlock(event, type);
    return (
      <span className="hover-menu-button" onMouseDown={onMouseDown} data-active={isActive}>
        <span>{icon}</span>
      </span>
    )
  };

  render() {
    return ReactDOM.createPortal(
      <div className="hover-menu" ref={this.props.menuRef}>
        {this.renderBlockButton(BLOCK.H1, 'H1')}
        {this.renderBlockButton(BLOCK.H2, 'H2')}
        {this.renderBlockButton(BLOCK.H3, 'H3')}
        {this.renderMarkButton(MARK.STRONG, 'B')}
        {this.renderMarkButton(MARK.EM, 'I')}
        {this.renderMarkButton(MARK.U, 'U')}
        {this.renderMarkButton(MARK.SPOILER, 'Spoiler')}
        {this.renderIconButton()}
      </div>,
      window.document.getElementById('root')
    )
  }
}
