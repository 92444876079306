import React, {Component} from 'react';
import classNames from 'classnames';
import {getStringFromTO} from '../../common/utils/i18nUtils';
import {setBackground} from '../../helpers/generic_helper';
import MaterialIcon from 'material-icons-react';

function previewClassNames(isOpen) {
  return classNames({
    'faq-previewer': true,
    'faq-previewer_show': isOpen,
  });
}

function getBackground(rule, palette) {
  return setBackground(palette[rule.color]);
}

export default class FaqPreview extends Component {
  render() {
    const {open, data, palette, rule_entities} = this.props;
    return (
      <div className={previewClassNames(open)}>
        <div className="faq-previewer__container">
          <div className="faq-previewer__header">
            <div className="faq-previewer__title">Question</div>
            <div className="faq-previewer__title">Short Answer</div>
            <span className="faq-previewer__text">{getStringFromTO(data.title)}</span>
            <span className="faq-previewer__text">{getStringFromTO(data.short_answer)}</span>
          </div>
          <div className="faq-previewer__body">
            <div className="faq-previewer__title">Long Answer</div>
            <div className="faq-previewer__text">{getStringFromTO(data.answer.filter(answer =>
              answer.type === 'long_answer')[0].text)}</div>
          </div>
          <div className="faq-previewer__title">Related Rules</div>
          <div className="faq-previewer__related">
            {data.related && data.related.map((rule, i) =>
              rule_entities[rule] &&
              <div className="faq-previewer__related-rule" key={rule_entities[rule].uuid}>
                <div className='faq-previewer__rule_wrap' style={getBackground(rule_entities[rule], palette)}>
                  <div className='faq-previewer__rule'>
                    <span className="faq-previewer__rule_icon">
                      <MaterialIcon icon='reorder' color='#202020' size='24'/>
                    </span>
                    {getStringFromTO(rule_entities[rule].title)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
