import React, {Component} from 'react'
import SmartListTree from './TreeList'
import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import propTypes from 'prop-types';
import {sortBy} from 'lodash/collection'

export default class index extends Component {

  static propTypes = {
    entities: propTypes.object.isRequired,
    component: propTypes.func.isRequired,
    maxDepth: propTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      tree: this.updateParentsAndLevels(this.createTree(this.objectToArray(JSON.parse(JSON.stringify(props.entities)))))
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({tree: this.updateParentsAndLevels(this.createTree(this.objectToArray(JSON.parse(JSON.stringify(nextProps.entities)))))})
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (JSON.stringify(this.state.tree) !== JSON.stringify(nextState.tree)) {
      this.setState({tree: this.updateParentsAndLevels(this.createTree(this.objectToArray(nextState.tree)), undefined, 1)})
    }
  }

  moveItem(uuid, overId, parentId) {

    if (uuid === overId && !parentId) return;

    let tree = this.objectToArray(JSON.parse(JSON.stringify(this.state.tree)));
    this.removeParents(tree);
    const item = {...this.findItem(uuid, tree)};

    if (!item.uuid) {
      return
    }
    const destination = parentId ? this.findItem(parentId, tree).children : tree;

    const removeNode = (uuid, items) => {
      for (const node of items) {
        if (node.uuid === uuid) {
          items.splice(items.indexOf(node), 1);
          return
        }

        if (node.children && node.children.length) {
          removeNode(uuid, node.children)
        }
      }
    };

    if (!overId) {
      removeNode(uuid, tree);
      destination.unshift(item);
    } else {
      const index = destination.indexOf(destination.filter(v => v.uuid === overId).shift());
      removeNode(uuid, tree);
      item.order = index;
      destination.splice(index, 0, item)
    }

    this.updateParentsAndLevels(tree, undefined, 1);

    const checkForDepthLimit = (tree) => {
      for (const node of tree) {
        if (node.level > this.props.maxDepth) return true;
        if (node.children && node.children.length) {
          if (checkForDepthLimit(node.children)) {
            return true;
          }
        }
      }
      return false;
    };

    if (checkForDepthLimit(tree)) {
      return
    }
    this.setState({tree});
  }

  droppedItem() {
    const arrayToObject = (array) =>
      array.reduce((obj, item) => {
        obj[item.uuid] = item;
        return obj
      }, {});

    let tree = [...this.state.tree];
    tree = this.flattenTree(tree);
    this.removeChildren(tree);

    this.props.onUpdate({
      menu: arrayToObject(tree)
    })
  }

  findItem(uuid, items) {
    for (const node of items) {
      if (node.uuid === uuid) {
        return node;
      }
      if (node.children && node.children.length) {
        const result = this.findItem(uuid, node.children);
        if (result) {
          return result
        }
      }
    }
    return false
  }

  isEmpty(obj) {
    var key;
    for (key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  createTree(array, parent, tree) {

    tree = typeof tree !== 'undefined' ? tree : [];
    parent = typeof parent !== 'undefined' ? parent : {uuid: ''};
    let that = this;
    let children = filter(array, function (child) {
      return child.parent === parent.uuid;
    });

    if (!isEmpty(children)) {
      if (parent.uuid === '') {
        tree = children;
      } else {
        parent['children'] = children;
      }
      each(children, function (child) {
        that.createTree(array, child)
      });
    }
    return tree;
  }

  flattenTree(array) {
    let result = [];
    let that = this;
    array.forEach(function (a) {
      const arr = a;
      result.push(arr);
      if (Array.isArray(a.children)) {
        result = result.concat(that.flattenTree(a.children));
      }
    });
    return result;
  };

  objectToArray(object) {
    return Object.keys(object).map(key => {
      return object[key];
    });
  }

  updateParentsAndLevels = (tree, uuid, level) => {

    tree = sortBy(tree, ['order']);

    for (const node of tree) {
      node.order = tree.indexOf(node);
      let that = this;
      if (uuid) {
        node.parent = uuid;
        node.level = level;
      }
      if (node.children && node.children.length) {
        that.updateParentsAndLevels(node.children, node.uuid, level + 1);
      }
    }
    return tree;
  };

  removeChildren(tree, uuid) {
    for (const node of tree) {
      if (node.children && node.children.length) {
        node.children = [];
      }
    }
    return tree;
  }

  removeParents(tree) {
    for (const node of tree) {
      let that = this;
      node.parent = '';
      node.level = 1;
      if (node.children && node.children.length) {
        that.removeParents(node.children);
      }
    }
  }

  render() {
    const {entities, ...rest} = this.props;
    /*TODO If possible, render calls should be reduced in the tree / it's items to make it more lightweight*/
    return <div className={'sortable-tree-list'}>
      {!this.isEmpty(this.state.tree) &&
      <SmartListTree
        parent={null}
        items={this.state.tree}
        move={this.moveItem.bind(this)}
        find={this.findItem.bind(this)}
        drop={this.droppedItem.bind(this)}
        {...rest}
      />}
    </div>
  }
}
