import {createSelector} from 'redux-orm';
import orm from '../orm';

const include = (obj, includes) => {
  const result = obj.ref;
  Object.keys(includes).forEach(i => {
    if (obj[i]) {
      result[i] = obj[i]
        .toModelArray()
        .map(childObj => include(childObj, includes[i]));
    }
  });
  return result || [];
};

const arraySelector = (model, includes = {}) =>
  createSelector(orm, session => {
    const ormModel = session[model];
    if (!ormModel) {
      console.warn(`Orm model ${model} not found`);
      return [];
    }
    return ormModel
      .all()
      .toModelArray()
      .map(obj => include(obj, includes));
  });

export default arraySelector;
