import {actions} from '../consts';

export const get = (params) => ({
  type: actions.API_MIDDLEWARE,
  payload: {
    method: 'get',
    success: set,
    url: `/api/v1/user/Organizations${organizationFilter(params)}`,
  }
});

export const set = (payload) => {
  let params = { validOrganization: false };

  if (payload.length > 0) {
    const organization = payload[0];
    params = {
      organizationId: organization.uuid,
      organizationFullName: organization.name,
      organizationSlug: organization.slug,
      validOrganization: true
    };
  }

  return ({
    type: actions.SET_ORGANIZATION,
    payload: params
  })
};

function isUuid(possibleUuid) {
  const result = possibleUuid.match(/^[^-]{8}-[^-]{4}-[^-]{4}-[^-]{4}-[^-]{8}/);
  return result !== null
}

function organizationFilter(params) {

  if (isUuid(params.orgId)) {

    const uuidFilter = {where: {'uuid': params.orgId}};
    return '?filter=' + JSON.stringify(uuidFilter);

  }

  const slugFilter = {where: {'slug': {ilike: params.orgId}}};
  return '?filter=' + JSON.stringify(slugFilter);
}


