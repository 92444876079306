import 'reset-css';
import 'normalize.css';
import './styles/main.scss';
import './custom.scss';
import 'symbol-observable'; // Polyfill Symbol.observable

import React from 'react';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { DragDropContextProvider } from 'react-dnd';
import promise from 'redux-promise-middleware';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { createReduxHistoryContext } from 'redux-first-history';
import { injectReducer } from 'redux-injector';
import { dynamicMiddlewaresInstance, store as dizedStore } from '@dized/ui/dist/redux/store';

import createRootReducer from './reducers'; // Ensure this is correctly imported
import Routes from './routes';
import api from './middleware/api_middleware';
import attempt from './middleware/attempt_middleware';
import HTML5Backend from 'react-dnd-html5-backend';

const history = createBrowserHistory();
const { routerMiddleware: reduxFirstRouterMiddleware, createReduxHistory } = createReduxHistoryContext({
  history,
  store: dizedStore,
  createRootReducer,
});

const logger = createLogger({
  collapsed: true
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const storeMiddleware = applyMiddleware(promise(), logger, thunk, reduxFirstRouterMiddleware, attempt, api);

const store = createStore(
  createRootReducer(history), // Use createRootReducer with history
  composeEnhancers(storeMiddleware)
);

// inject application middlewares
dynamicMiddlewaresInstance.addMiddleware(reduxFirstRouterMiddleware);
dynamicMiddlewaresInstance.addMiddleware(api);

// inject application reducers
Object.entries(createRootReducer(history)).forEach(([key, reducer]) =>
  injectReducer(key, reducer),
);

const reduxHistory = createReduxHistory(store);

ReactDOM.render(
  <Provider store={store}>
    <Router history={reduxHistory}>
      <DragDropContextProvider backend={HTML5Backend}>
        <Routes history={reduxHistory} />
      </DragDropContextProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
