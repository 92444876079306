const idGroup = '(?<id>[a-z0-9_-]{11})';
const startGroup = 't=(?<start>[0-9]+)s?';

const allowedPatterns = [
  // plain ID
  new RegExp(`^${idGroup}$`, 'i'),
  // YT Player "Copy Video URL" menu
  // https://youtu.be/<id>
  // YT Player "Copy Video URL at current time" menu
  // https://youtu.be/<id>?t=<start>
  new RegExp(`^https?://youtu.be/${idGroup}(\\?${startGroup})?$`, 'i'),
  // YT Player "Watch on YouTube" button or copied from browser URL
  // https://www.youtube.com/watch?v=<id>
  // https://www.youtube.com/watch?v=<id>&t=<start>s
  new RegExp(`^https?://www.youtube.com/watch\\?v=${idGroup}(&${startGroup})?$`, 'i'),
];

/**
 * Parse a YouTube URL or ID. Check for valid ID and extract optional parameters
 *
 * @param {string} string - string to check
 * @returns {object}        module data, if valid, otherwise undefined
 */
export const parseIdtoData = string => {
  if (!string) return;

  // find the first succesful match
  let match;
  for (const pattern of allowedPatterns) {
    match = pattern.exec(string);
    if (match) break;
  }
  if (!match) return;

  // generate module data from successful match
  const {id, start = 0} = match.groups;
  return {
    end: 0,
    id,
    start: +start, // convert to number
  };
};
