import React, { Component } from 'react';

import TextWidget from '../TextWidget';
import ImageWidget from '../ImageWidget';

export default class ImageTextWidget extends Component {

  render() {
    const { isReversed } = this.props;
    return (
      !isReversed ? (
        <React.Fragment>
			  <div className="col s-1">
			  <div className="rule-builder__img rule-builder__img_left">
              <ImageWidget {...this.props} />
            </div>
          </div>
          <div className="col s-2">
            <TextWidget {...this.props} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="col s-2">
			  <TextWidget {...this.props} />
          </div>
          <div className="col s-1">
            <div className="rule-builder__img rule-builder__img_right">
              <ImageWidget {...this.props} />
            </div>
          </div>
        </React.Fragment>
      )
    )
  }
}
