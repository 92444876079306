import React from 'react';
import uuid4 from 'uuid4';
import moment from 'moment';
import values from 'lodash/values';
import {actions, imageSizes} from '../consts';
import helpers from '../helpers';
import {getDefaultColor} from './generic_helper';
import {cloneDeep} from 'lodash/lang';
import {createTO} from '../common/utils/i18nUtils';
import {defaultLocale} from '../helpers/LocaleList';

export const nextNameId = (entities = {}, options = {}) => {

  let prefix = options.prefix;
  const collection = values(entities),
    next_id = collection.length > 0
      ? collection
        .map(entity => {
          prefix = prefix ? prefix : entity.name[0];
          return entity.name.match(/\d+/g)
            .map(value => +value)[0]
        })
        .sort((a, b) => b - a)[0] + 1 : 1;

  return next_id > 9 ? `${prefix}${next_id}.0` : `${prefix}0${next_id}.0`;
};

export const createMenu = ({locale = defaultLocale, name = '', palette = [getDefaultColor()], order, type = '', method, parent, ...rest}) => {
  const uuid = uuid4();
  const menu_entity = {
    name,
    uuid,
    parent: parent,
    children: [],
    ruleOrder: [],
    color: Math.floor(Math.random() * palette.length),
    title: createTO('', locale, {
      application: 'rules-ct',
      description: 'category title'
    }),
    description: createTO('', locale, {
      application: 'rules-ct',
      description: 'category description'
    }),
    icon: {
      _type: 'ao1',
      assetId: '',
      attr: {
        aspectRatio: '1:1',
        locale,
        mimeType: 'image/png, image/jpeg',
        size: imageSizes.MENU_CATEGORY_ICON_SIZE
      },
      url: '/star.png'
    },
    type: 'menu',
    level: 1,
    order: order
  };
  return method({
    type: actions.ADD_TO_VERSION,
    menu: menu_entity,
    parent: menu_entity.uuid,
    processing: menu_entity
  });
};

export const createRule = ({locale = defaultLocale, name = '', filter, palette = [getDefaultColor()], type = '', method, ...rest}) => {
  const uuid = uuid4();

  function createColor() {
    if (filter.uuid) {
      return filter.color
    }
    return null;
  }

  const rule_entity = {
    name,
    uuid,
    parents: uuid,
    color: createColor(),
    title: createTO('', locale, {
      application: 'rules-ct',
      description: 'rule title'
    }),
    description: createTO('', locale, {
      application: 'rules-ct',
      description: 'rule description'
    }),
    icon: {
      _type: 'ao1',
      assetId: '',
      attr: {
        aspectRatio: '1:1',
        locale,
        mimeType: 'image/png, image/jpeg',
        size: imageSizes.RULE_ICON_SIZE
      },
      url: '/star.png'
    },
    type: 'rule',
    updated: moment().format(),
    created: moment().format(),
    html: '',
    modules: [],
    related: [],
    images: [],
    keywords: [],
    tips: []
  };

  let parent;
  if (filter.uuid) {
    parent = {
      uuid: rule_entity.uuid,
      data: filter.uuid ? filter.uuid.split(',') : []
    }
  } else {
    parent = rule_entity.uuid;
  }

  return method({
    type: actions.ADD_TO_VERSION,
    rule: rule_entity,
    parent: parent,
    processing: rule_entity
  });
};

export const createQuestion = (params = {}) => {

  const {method, ...defaultValues} = params;
  const uuid = uuid4();
  const question_entity = {
    uuid,
    type: 'question',
    modules: [],
    rules: [], /*TODO this is now related[], to be dropped off in few releases*/
    related: [],
    short_answer: {},
    answer: [],
    title: {},
    color: null,
  };

  return method({
    type: actions.ADD_TO_VERSION,
    question: {
      ...question_entity,
      ...defaultValues
    }
  });
};

export const initNewFaq = ({locale = defaultLocale, title, pageObject}) => {
  return {
    title: createTO(title, locale, {
      application: 'rules-ct',
      description: 'question title'
    }),
    answer: pageObject
      ? [createLongAnswer(locale), pageObject]
      : [createLongAnswer(locale)],
    short_answer: createTO('', locale, {
      application: 'rules-ct',
      description: 'question short answer'
    }),
    editor: false,
    titleInputClassName: 'input input_filter'
  };
}

export const createNewPageObject = (uuid) => {
  return {
    type: 'page',
    uuid: uuid,
    modules: [] /*TODO this will later contain the modules when they are made selectable*/
  }
}

export const createLongAnswer = (locale) => {
  return {
    type: 'long_answer',
    text: createTO('', locale, {
      application: 'rules-ct',
      description: 'question answer title'
    })
  };
};

export const sortRules = (menu, collection) => {
  if (menu) {
    const order = menu.ruleOrder && menu.ruleOrder;
    const sorting = order ? order.filter(o => collection.includes(o)) : collection;
    return collection.sort(function (a, b) {
      return sorting.indexOf(a) - sorting.indexOf(b);
    });

  }
  return collection;

};

/**
 * Sorts the rule order for a category entity while the category is active.
 * @param {object} category entry that is active
 * @param {array} array of rules within the selected category
 * @param {function} store update method
 * @returns {function} Entities reducer call for menus
 */
export const updateRuleOrder = ({menu, rules, method}) => {

  if (menu) {
    let menuClone = cloneDeep(menu);
    if (menu.ruleOrder && !helpers.generic.compareArrays(menu.ruleOrder, rules)) {

      // Rule no longer under a category, remove it
      for (const rule in menuClone.ruleOrder) {
        if (!rules.includes(menuClone.ruleOrder[rule])) {
          menuClone.ruleOrder.splice(menuClone.ruleOrder.indexOf(menuClone.ruleOrder[rule]), 1)
        }
      }

      // Added a new rule under a category
      for (const rule in rules) {
        if (!menuClone.ruleOrder.includes(rules[rule])) {
          menuClone.ruleOrder.splice(0, 0, rules[rule]);
        }
      }

      return method({
        menu: menuClone
      });

    } else if (!menu.ruleOrder) {
      menuClone.ruleOrder = [];
      return method({
        'menu': menuClone
      });
    }
  }
};

export const sortNewRuleToTop = (nextProps, state, nextState, onUpdate) => {

  const rules = nextProps.rules;
  if ((nextState.hasNewRule !== state.hasNewRule)
    && (nextState.hasNewRule !== false) && rules.length > 0
    && nextProps.filter.type === 'filter::ALL') {

    rules.unshift(rules[rules.length - 1]);
    rules.pop();

    onUpdate({
      'rules': rules
    })
  }

  if (state.hasNewRule) {
    return false;
  }
};

export const getModalActionOne = (action, cancel, confirm, save) => {
  switch (action) {
    case 'exit': return () => save(true);
    case 'publish': return cancel;
    case 'reminder': return () => {
      save(false);
      cancel();
    }
    default: return confirm;
  }
};

export const getModalActionTwo = (action, callback) => {
  switch (action) {
    case 'exit': return callback;
    default: return undefined;
  }
};

export const getActionOneText = (action) => {
  switch (action) {
    case 'exit': return 'Save and quit';
    case 'publish': return 'OK';
    case 'reminder': return 'Save draft';
    default: return 'Confirm';
  }
};

export const getActionTwoText = (action) => {
  switch (action) {
    case 'exit': return 'Quit without saving';
    default: return undefined;
  }
};

export const getActionCancelText = (action) => {
  switch (action) {
    case 'exit': return 'No, go back';
    default: return 'Cancel';
  }
};

export const getModalHeaderText = (action, type) => {
  switch (action) {
    case 'exit': return 'Are you sure you want to quit?';
    case 'publish': return 'Available Soon';
    case 'reminder': return 'Time to save?';
    default: return `Remove ${type} entity?`;
  }
};

export const getModalContent = (action) => {
  switch (action) {
    case 'publish':
      return (
        <div>
          <p>READY TO PUBLISH?</p>
          <p>Make sure to check the content, use <a href="http://bit.ly/dized-rules-checklist" target="_blank">this checklist</a> for reference.</p>
          <p>Once you're ready to publish, let us know by emailing <a href="mailto:b2b@dized.com">b2b@dized.com</a>, then we'll review your content
            and get it into Dized! Once we have developed the tools further, this publishing process will become easier.</p>
          <p>Remember, that after publishing you can always come back to this tool to update the content.</p>
        </div>
      );
    case 'reminder':
      return (
        <div>
          <p>You haven&apos;t saved your work in a while.</p>
          <p>
            It&apos;s recommended to save every now and then to make sure you don&apos;t lose any work.
            Just click the SAVE DRAFT button and keep on the good work!
          </p>
        </div>
      );
    default: return (
      <div></div>
    );
  }
};
