import {actions} from '../consts';

export const setTitle = (params) => ({
  type: actions.SET_TITLE,
  payload: {
    subtitle: params.subtitle
  }
});

export const clearTitle = () => ({
  type: actions.CLEAR_TITLE,
  payload: {
    subtitle: ''
  }
});



