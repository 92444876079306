import {actions} from '../consts';
import axios from 'axios';

let isRunningAsyncActions = 0;

const api = ({dispatch, getState}) => next => async action => {

  if (action.type !== actions.API_MIDDLEWARE) {
    return next(action)
  }

  const {method, url, success} = action.payload;

  if (!method || !url) {
    throw Error('API Method or Url is missing');
  }

  dispatch({type: actions.FETCHING_START});
  try {
    ++isRunningAsyncActions;
    let {data} = await axios({method, url, data: action.payload.data});
    dispatch(success(data));
    --isRunningAsyncActions;
  } catch (e) {
    throw e;
  }

  if (!isRunningAsyncActions) {
    dispatch({type: actions.FETCHING_STOP});
  }

  next(action);
};

export default api;
