import { actions } from '../consts';

export default (state = false, action) => {
  switch (action.type) {
    case actions.FETCHING_START:
      return true;
    case actions.FETCHING_STOP:
      return false;
    default:
      return state;
  }
}