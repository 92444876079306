import {genericReduce} from '@dized/ui/dist/redux/OrmHelper';
import {v4 as uuidv4} from 'uuid';
import AssetActions from '../../actions/AssetActions';

export default function (session, action) {
  const {Asset, AssetData} = session;
  switch (action.type) {
    case AssetActions.UPLOAD_ASSET.FULFILLED:
      action.payload.data.forEach(assetList =>
        assetList.forEach(asset => {
          const {id} = asset;
          const instance = Asset.withId(id);
          if (instance) {
            instance.update(asset);
          } else {
            Asset.create(asset);
          }
          asset.assetData.map(assetData => {
            const {assetId} = assetData;
            const instance = AssetData.get({assetId});
            if (instance) {
              instance.update(assetData);
            } else {
              AssetData.create({...assetData, id: uuidv4()});
            }
          });
        })
      );
      break;
    case AssetActions.DELETE_ASSET.FULFILLED: {
      const {id} = action.payload.marker;
      AssetData.filter({assetId: id}).delete();
      Asset.withId(id).delete();
      break;
    }

    default:
      genericReduce(Asset, AssetActions, action);
      break;
  }
  return session;
}
