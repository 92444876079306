import React, {Component} from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

export default class Button extends Component {
  static propTypes = {
	  children: propTypes.any,
	  secondary: propTypes.bool,
	  close: propTypes.bool,
	  inactive: propTypes.bool
  };

  static defaultProps = {
	  children: 'Button',
	  secondary: false,
	  inactive: false
  };

  render() {
	  const {secondary, inactive, close, ...rest} = this.props;
	  return (
	    <button
	      {...rest}
	      className={buttonClassNames(this.props)}/>
	  )
  }
}

function buttonClassNames(props) {
  return classNames({
    button: true,
    button_secondary: props.secondary,
    button_close: props.close,
    button_inactive: props.inactive
  });
}
