import React, {Component} from 'react'
import {DragSource, DropTarget} from 'react-dnd'
import SmartListTree from './TreeList'
import flow from 'lodash/flow'
import propTypes from 'prop-types';
import {cloneDeep} from 'lodash/lang'
import {getEmptyImage} from 'react-dnd-html5-backend';

const source = {
  beginDrag(props) {
    return {
      uuid: props.uuid,
      index: props.index,
      parent: props.parent,
      items: props.item.children
    }
  },
  isDragging(props, monitor) {
    return props.uuid === monitor.getItem().uuid
  }
};

const target = {
  canDrop() {
    return false
  },
  hover(props, monitor, component) {
    const {uuid: draggedId, index: draggedIndex, items} = monitor.getItem();
    const {uuid: overId, index: overIndex} = props;

    if (!component) return;

    function draggedOverDescendant() {
      if (draggedId === props.parent) {
        return true
      }

      const descendantNode = props.find(props.parent, items);
      if (descendantNode) {
        return true;
      }
    }

    if (draggedId === overId || draggedOverDescendant()) return;
    if (!monitor.isOver({shallow: true})) return;

    const {decoratedComponentInstance} = component;
    const hoverBoundingRect = decoratedComponentInstance.treeListItem.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset()

    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    const onHoverAbove = draggedIndex > overIndex && hoverClientY > hoverMiddleY;
    const onHoverBelow = draggedIndex < overIndex && hoverClientY < hoverMiddleY;

    if (props.item.children.length > 0) {
      if (props.item.children[0].uuid !== draggedId) return;
    }

    if (onHoverAbove || onHoverBelow) return;
    props.move(draggedId, overId, props.parent)
  }

};

class TreeListItem extends Component {

  static propTypes = {
    uuid: propTypes.any.isRequired,
    parent: propTypes.any,
    item: propTypes.object,
    move: propTypes.func,
    find: propTypes.func,
    drop: propTypes.func
  };

  componentDidMount() {
    const {connectDragPreview} = this.props;
    connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }

  hoc(Component) {
    return (props) => <Component {...props} />
  }

  removeChildren = (item) => {
    const clone = cloneDeep(item);
    if (clone.children && clone.children.length) {
      clone.children = [];
    }
    return clone;
  };

  hasChildren = (item) => {
    return (item && item.children) ? item.children.length > 0 : false;
  };

  render() {

    const {
      connectDropTarget, connectDragSource, isDragging,
      uuid, parent, item, move, find, index, drop, ...rest
    } = this.props;

    return connectDropTarget(
      <div className="tree-list-node" style={{opacity: isDragging ? 0.3 : 1}}>
        {connectDragSource(
          <div className="tree-list-item" ref={treeListItem => (this.treeListItem = treeListItem)}>
            {this.hoc(this.props.component)({
              data: this.removeChildren(item),
              hasChildren: this.hasChildren(item), ...rest
            })} </div>
        )}
        <SmartListTree
          parent={item.uuid}
          items={item.children}
          move={move}
          find={find}
          drop={drop}
          {...rest}
        />
      </div>
    )
  }
}

export default flow([
  DragSource('ITEM', source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  })),
  DropTarget('ITEM', target, connect => ({
    connectDropTarget: connect.dropTarget()
  }))]
)(TreeListItem);
