import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {genericActions} from '@dized/ui/dist/redux/OrmHelper';
import {patchNoHeader} from '@dized/ui/dist/redux/RequestHelper';

export const apiPath = '/api/v1/assets/folders';
const modelName = 'FOLDER';

export default initActions('FolderAction', {
  ...genericActions({modelName}, apiPath),
  UPDATE_FOLDER: (id, attrs) => patchNoHeader(`${apiPath}/${id}`, attrs),
});
