/**
 * List of supported locales, in DropDown format
 */
export const localeList = [
  {value: 'bg-BG', label: 'Bulgarian'},
  {value: 'ca-ES', label: 'Catalan (Spain)'},
  {value: 'cs-CS', label: 'Czech'},
  {value: 'da-DA', label: 'Danish'},
  {value: 'de-DE', label: 'German'},
  {value: 'el-GR', label: 'Greek'},
  {value: 'en-US', label: 'English (US)'},
  {value: 'es-ES', label: 'Spanish'},
  {value: 'et-EE', label: 'Estonian'},
  {value: 'fi-FI', label: 'Finnish'},
  {value: 'fr-FR', label: 'French'},
  {value: 'hu-HU', label: 'Hungarian'},
  {value: 'is-IS', label: 'Icelandic'},
  {value: 'it-IT', label: 'Italian'},
  {value: 'ja-JP', label: 'Japanese'},
  {value: 'ko-KR', label: 'Korean'},
  {value: 'lt-LT', label: 'Lithuanian'},
  {value: 'lv-LV', label: 'Latvian'},
  {value: 'nb-NO', label: 'Norwegian'},
  {value: 'nl-NL', label: 'Dutch'},
  {value: 'pl-PL', label: 'Polish'},
  {value: 'pt-BR', label: 'Portuguese (Brazil)'},
  {value: 'pt-PT', label: 'Portuguese'},
  {value: 'ro-RO', label: 'Romanian'},
  {value: 'ru-RU', label: 'Russian'},
  {value: 'sk-SK', label: 'Slovak'},
  {value: 'sv-SE', label: 'Swedish'},
  {value: 'zh-CN', label: 'Chinese (Mandarin)'},
  {value: 'zh-HK', label: 'Chinese (Cantonese)'},
  {value: 'zh-TW', label: 'Chinese (Taiwanese)'},
];

/**
 * List of supported locales, sorted by name, in DropDown format
 */
export const sortedLocaleList = localeList.sort((a, b) => a.label.localeCompare(b.label));

/**
 * Get name of a locale
 *
 * @param {string} locale - locale identifier
 * @returns {string}        locale name
 */
export const getLocaleName = locale => {
  const entry = localeList.find(l => l.value === locale);
  return entry ? entry.label : undefined;
}

export const defaultLocale = 'en-US';
