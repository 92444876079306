import React, { Component } from 'react';
import Placeholder from '../Placeholder';

export default class RuleHTMLPreviewer extends Component {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
    this.resizeIframe = this.resizeIframe.bind(this);
  }

  resizeIframe(height) {
    this.iframe.current.style.height = height + 16 + 'px';
  }

  componentWillReceiveProps() {
	  if (this.iframe.current) {
		  if (this.iframe.current.contentDocument.body) {
			  this.resizeIframe(this.iframe.current.contentDocument.body.clientHeight);
		  }
	  }
  }

  render() {
    return this.props.html 
      ? <iframe 
        title="rule-preview"
        ref={this.iframe}
        width="100%"
        srcDoc={this.props.html}
        scrolling="no"
        frameBorder="0" />
      : <Placeholder>No content to show</Placeholder>
  }
}
