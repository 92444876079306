import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import {withRouter} from 'react-router'
import {ShellHeader} from '@dized/ui';

import Subheader from '../components/Subheader';

/*
 * See DIZ-1796
import GamesTable from '../views/GamesTable';
import VersionsTable from '../views/VersionsTable';
import ProjectsTable from '../views/ProjectsTable';
 */
import ProjectOverview from '../views/ProjectOverview';
import RuleOverview from '../views/RuleOverview';
import FaqOverview from '../views/FaqEditor';

import {get} from '../actions/organization_action';


const mapStateToProps = ({db, ui}) => ({
  organization: db.organization,
  title: ui.title
});

const mapDispatchToProps = {
  getOrganization: (params) => get(params)
};


class Routes extends Component {

  componentDidMount() {
    const currentUrl = this.props.history.location.pathname;
    const regEx = currentUrl.match('^/([^/]*)');
    this.orgSlug = '';

    if (regEx.length === 2) {
      this.orgSlug = regEx[1];

      this.props.getOrganization({orgId: this.orgSlug});
    }
  }

  componentDidUpdate() {
    const {organization} = this.props;

    if (organization.hasInitialized && !organization.validOrganization) {
      this.redirectToPortalDashboard();
    }
  }

  redirectToPortalDashboard() {
    const baseURL = window.__SERVER_DATA__ && window.__SERVER_DATA__.REACT_APP_ORG_PORTAL
      ? window.__SERVER_DATA__.REACT_APP_ORG_PORTAL
      : process.env.REACT_APP_ORG_PORTAL;

    window.location.href = `${baseURL}/${this.orgSlug}/`;
  }

  onHamburgerClick = () => {
    this.redirectToPortalDashboard();
  }

  render() {
    const {title} = this.props;
    const gameTitle = title.subtitle ? `${title.subtitle}` : '';

    return (
      <div>
        <ShellHeader onMenuClick={this.onHamburgerClick}>
          <a href="https://portalguide.dized.com/rules-tool-overview" target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>Guide</a>
        </ShellHeader>
        <Subheader
          icon="dized-rlt"
          title="Rules Creation Tool"
          description={gameTitle} />
        <Switch>
          <Route exact path="/:orgId/version/:version/rule/:rule" component={RuleOverview} />
          <Route exact path="/:orgId/version/:version/faq/:faq" component={FaqOverview} />
          <Route exact path="/:orgId/version/:id" component={ProjectOverview} />
          {/*
            * DIZ-1796: Disable routes that collide with org-portal functionality
            *
          <Route exact path="/:orgId/versions/:id" component={VersionsTable} />
          <Route exact path="/:orgId/projects/:id" component={ProjectsTable} />
          <Route exact path="/:orgId" component={GamesTable} />
           */}
        </Switch>
      </div>)
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes));
