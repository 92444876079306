import {actions} from '../consts';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import helpers from '../helpers';

export const parents_entities_reducer = (state = {}, action) => {
  const {parent, uuid} = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      const {entities} = action.payload;
      return entities.parents || state;
    case actions.ADD_TO_VERSION:
      if (parent && parent.data) {
        return parent ? {...merge({}, omit(state, parent.uuid)), [parent.uuid]: parent.data}
          : state;
      }

      return parent ? merge({}, state, {[parent]: []})
        : state;

    case actions.UPDATE_VERSION:
      return parent
        ? {...merge({}, omit(state, parent.uuid)), [parent.uuid]: parent.data}
        : state;
    case actions.REMOVE_FROM_VERSION:
      return mapValues(omit(state, uuid), value => value.filter(parent => parent !== uuid));
    case actions.RESET_CONTENT:
      return {};
    default:
      return state;
  }
}

export const questions_entities_reducer = (state = {}, action) => {
  const {type, uuid, question, questions} = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      const {entities} = action.payload;
      return entities.questions || state;
    case actions.UPDATE_VERSION:
    case actions.ADD_TO_VERSION:
      if (question) {
        return mergeWith({}, state, {[question.uuid]: question}, helpers.generic.replaceArrayValue);
      } else if (questions) {
        return mergeWith({}, questions);
      }
      return state;
    case actions.REMOVE_FROM_VERSION:
      if (type === 'rule') {
        return mapValues(
          state,
          question => ({
            ...question, related: question.related.filter(item_uuid => item_uuid !== uuid),
            answer: question.answer.filter(answer => answer.uuid !== uuid)
          }));
      }
      if (question) {
        return merge({}, omit(state, question));
      }
      if (type === 'question') {
        return merge({}, omit(state, action.payload.uuid));
      }
      return state;
    case actions.RESET_CONTENT:
      return {};
    default:
      return state;
  }
}

export const modules_entities_reducer = (state = {}, action) => {
  const {module, modules} = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      const {entities} = action.payload;
      return entities.modules || state;
    case actions.UPDATE_VERSION:
    case actions.ADD_TO_VERSION:
      if (module) {
        return mergeWith({}, state, {[module.uuid]: {...module}});
      } else if (modules) {
        return mergeWith({}, modules);
      }
      return state;

    case actions.REMOVE_FROM_VERSION:
      if (modules) {
        return merge({}, omit(state, modules));
      }

      if (module) {
        return merge({}, omit(state, module));
      }
      return state;
    case actions.RESET_CONTENT:
      return {};
    default:
      return state;
  }
}

export const rules_entities_reducer = (state = {}, action) => {
  const {rule, type, uuid, module} = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      const {entities} = action.payload;
      return entities.rules || state;
    case actions.UPDATE_VERSION:
    case actions.ADD_TO_VERSION:
      return rule
        ? mergeWith({}, state, {[rule.uuid]: rule}, helpers.generic.replaceArrayValue)
        : state;
    case actions.REMOVE_FROM_VERSION:
      if (module) {
        const stateCopy = merge({}, state);
        stateCopy[rule.uuid].modules = stateCopy[rule.uuid].modules.filter(module_uuid => module_uuid !== module);
        return stateCopy;
      }
      if (type === 'rule') {
        return mapValues(
          merge({}, omit(state, uuid)),
          rule => ({...rule, related: rule.related.filter(related_rule => related_rule !== uuid)})
        );
      }
      return state;
    case actions.RESET_CONTENT:
      return {};
    default:
      return state;
  }
}

/*eslint complexity: ["error", 20]*/

export const menus_entities_reducer = (state = {}, action) => {
  const {menu, type, uuid} = action.payload || {};
  switch (action.type) {
    case actions.SET_VERSION:
      const {entities} = action.payload;
      return entities.menus || state;
    case actions.UPDATE_VERSION:
    case actions.ADD_TO_VERSION:
      if (menu) {
        if (menu.order === -1) {
          const sortedMenu = mapValues(state, item => ({...item, order: item.order + 1}));
          return menu.uuid
            ? mergeWith({}, sortedMenu, {[menu.uuid]: {...menu, order: 0}}, helpers.generic.mergeAssignArray)
            : menu
              ? {...menu}
              : state;
        }

        return menu.uuid
          ? mergeWith({}, state, {[menu.uuid]: menu}, helpers.generic.mergeAssignArray)
          : menu
            ? {...menu}
            : state;
      }
      return menu
        ? merge({}, state, {[menu.uuid]: menu})
        : state;
    case actions.REMOVE_FROM_VERSION:
      if (type === 'menu') {
        const removedIndex = state[uuid].order;
        const sortedMenu = mapValues(state, item => ({
          ...item,
          order: item.order > removedIndex ? item.order - 1 : item.order
        }));
        return merge({}, omit(sortedMenu, uuid))
      } else if (type === 'rule') {
        return mapValues(
          state,
          menu => menu.ruleOrder
            ? ({...menu, ruleOrder: menu.ruleOrder.filter(rule => rule !== uuid)})
            : ({...menu}))
      }
      return state;
    case actions.RESET_CONTENT:
      return {};
    default:
      return state;
  }
};
