import {initActions} from '@dized/ui/dist/redux/ActionHelper';
import {get, call} from '@dized/ui/dist/redux/RequestHelper';

const apiPath = '/api/v1/assets/asset';

export default initActions('AssetDataAction', {
  /**
   * Fetches the Asset Data in one request filtered by the folder.
   *
   * @param {string} id - UUID of the folder to search for
   * @returns {Promise}   fulfills with asset data
   */
  FETCH_ASSET_DATA_BY_FOLDER: id =>
    get(apiPath + '?filter=' + JSON.stringify({include: ['assetData'], where: {folder: id}})),
  CLEAR_ASSET_DATA: () => call(),
});
