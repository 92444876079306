import React, {Component} from 'react';
import propTypes from 'prop-types';
import Button from '../Button';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';

const customModalStyles = {
  height: 'auto',
  bottom: 'auto',
  top: '50%',
  transform: 'translateY(-50%)'
};

export default class Modal extends Component {
  static propTypes = {
	  show: propTypes.bool,
	  header: propTypes.string,
	  onActionOne: propTypes.func, // onConfirm
	  onActionTwo: propTypes.func, // Anything
	  onCancel: propTypes.func.isRequired
  };

  static defaultProps = {
	  show: false,
	  actionOneText : 'Confirm',
	  actionTwoText : '',
	  cancelText : 'Cancel',
	  hideCancel: false,
  };

  getActions = () => {
	  if (this.props.onActionOne && this.props.onActionTwo) {
	    return (
	      <div className="modal__wrapper__secondary">
	        {this.props.onActionOne &&
					<div className="modal__button">
					  <Button onClick={() => this.props.onActionOne()} secondary>{this.props.actionOneText}</Button>
					</div>}
	        {this.props.onActionTwo &&
					<div className="modal__button">
					  <Button onClick={() => this.props.onActionTwo()} secondary>{this.props.actionTwoText}</Button>
					</div>}
	        {!this.props.hideCancel && <div className="modal__button">
	          <Button onClick={() => this.props.onCancel()}>{this.props.cancelText}</Button>
	        </div>}
	      </div>
	    )
	  } else if (this.props.onActionOne) {
	    return (
	      <div className="modal__wrapper">
	        {!this.props.hideCancel && <div className="modal__button">
	          <Button onClick={() => this.props.onCancel()}>{this.props.cancelText}</Button>
	        </div>}
	        {this.props.onActionOne &&
					<div className="modal__button">
					  <Button onClick={() => this.props.onActionOne()} secondary>{this.props.actionOneText}</Button>
					</div>}
	      </div>
	    )
	  } 
	  return <div></div>
		
  };

  render() {
	  const {show, header, children, onCancel} = this.props;
	  return (
	    <Rodal visible={show} onClose={onCancel} customStyles={customModalStyles}>
	      <div className="modal">
	        <div className="modal__header">{header}</div>
	        <div className="modal__text">{children}</div>
	        {this.getActions()}
	      </div>
	    </Rodal>
	  )
  }
}
