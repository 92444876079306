import React, {Component} from 'react';
import {findDOMNode} from 'react-dom'
import MaterialIcon from 'material-icons-react';
import classNames from 'classnames';
import RulePreview from '../RulePreview';
import {Input} from '../Input';
import {Icon} from '../Icon';
import {limits} from '../../consts'
import helpers from '../../helpers';
import {setBackground} from '../../helpers/generic_helper';
import {getStringFromTO} from '../../common/utils/i18nUtils';

const styles = (state, props) => {
  const {data, palette} = props;

  return setBackground(palette[data.color]);
};

const iconName = (isOpen) => classNames({
  keyboard_arrow_right: !isOpen,
  keyboard_arrow_down: isOpen,
});

export default class Rule extends Component {

  constructor(props) {
    super(props);
    this.state = {open: false};
    this.onToggleMode = this.onToggleMode.bind(this);
    this.onHandleUpdate = this.onHandleUpdate.bind(this);
    this.handleRemoveAttempt = this.handleRemoveAttempt.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onEditorMode = this.onEditorMode.bind(this);
    this.openAssetManager = this.openAssetManager.bind(this);
  }

  componentDidMount() {
    this.props.callback(this.onDragEnd);
  }

  onToggleMode() {
    const {open} = this.state;
    this.setState({open: !open});
  }

  onHandleUpdate(data) {
    const {parents, modules, questions_entities} = this.props;

    this.props.onUpdate({
      processing: {
        ...data,
        parents: parents[data.uuid]
      }
    });

    this.props.onUpdate({
      processing: {
        modules,
        questions: questions_entities
      }
    });
  }

  handleRemoveAttempt(event) {
    event.stopPropagation();
    const {data, onAttempt} = this.props;
    onAttempt({
      type: 'rule',
      uuid: data.uuid,
      modules: data.modules
    });
  }

  onDragEnd(result, target, collection, sortingMethod) {
    if (!result.destination) {
      return;
    }

    const sorting = sortingMethod(
      collection,
      result.source.index,
      result.destination.index
    );

    if (target && target.uuid) {
      target.ruleOrder = sorting;
      this.props.onUpdate({
        'menu': target
      })

    } else {
      this.props.onUpdate({
        'rules': sorting
      })
    }
  };

  onEditorMode(event) {
    event.stopPropagation();
    this.props.onUpdate({processing: this.props.data});
  }

  openAssetManager(event) {
    event.stopPropagation();
    this.props.showAsset({show: true, target: this.props.data});
  }

  openRuleView = (params, data) => {
    this.props.onDelete({processing: {uuid: data.uuid}});
    this.props.onEditClick(`/${params.orgId}/version/${params.id}/rule/${data.uuid}`);
  };

  render() {
    const {data, params} = this.props;
    const {open} = this.state;
    const quickEditor = this.props.processing[data.uuid];

    return (
      !quickEditor ?
        <div onDoubleClick={() => this.openRuleView(params, data)}>
          <div className="rule"
            style={styles(this.state, this.props)} onClick={this.onToggleMode}>
            <div className="rule__icon" onClick={this.openAssetManager}>
              <Icon className="rule__img" src={data.icon.url} alt="" />
            </div>
            <div className="rule__title">{getStringFromTO(data.title)}</div>
            <div className="rule__buttons">
              <div className="rule__button" onClick={this.onEditorMode}>
                <MaterialIcon icon="mode_edit" color="#fff" />
              </div>
              <div className="rule__button" onClick={() => this.openRuleView(params, data)}>
                <MaterialIcon icon="build" color="#fff" />
              </div>
              <div className="rule__button" onClick={this.handleRemoveAttempt}>
                <MaterialIcon icon="delete_forever" color="#fff" />
              </div>
              <MaterialIcon icon={iconName(open)} color="#fff" />
            </div>
          </div>
          <RulePreview {...this.props} open={open} />
        </div>
        : <RuleEditor {...this.props} />
    )
  }
}

class RuleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {...this.props.data};
    this.onChange = this.onChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSave = this.onSave.bind(this);
    this.openAssetManager = this.openAssetManager.bind(this);
  }

  openAssetManager(event) {
    event.stopPropagation();
    this.props.showAsset({show: true, target: this.state});
  }

  onChange(event) {
    const {target} = event;
    this.setState({title: {...this.state.title, text: target.value}});
  }

  onBlur(event) {
    event.stopPropagation();
    this.props.onUpdate({rule: this.state});
  }

  onSave(event) {
    event.stopPropagation();
    if (this.state.title.text) {
      this.props.onUpdate({rule: this.state});
      this.props.onDelete({processing: {uuid: this.state.uuid}});
    }
  }

  onCancel(event) {
    event.stopPropagation();
    (!this.props.data.title.text && !this.props.processing[this.state.uuid].title.text)
      ? this.props.onDelete({type: 'rule', uuid: this.state.uuid})
      : this.props.onUpdate({rule: this.props.processing[this.state.uuid]});
    this.props.onDelete({processing: {uuid: this.state.uuid}});
  }

  focusInput = (component) => {
    if (component) {
      findDOMNode(component).focus();
    }
  };

  handleEnterKey = (event) => {
    this.onBlur(event);
    this.onSave(event);
  }

  handleEscapeKey = (event) => {
    this.onCancel(event);
  }

  componentDidUpdate(prevProps) {
    // NOTE: The parent component updates the icon in props, 
    // so we need to sync the state to reflect those changes.
    if (prevProps.data.icon.assetId !== this.props.data.icon.assetId) {
      this.setState({
        icon: { ...this.props.data.icon },
      });
    }
  }

  render() {
    const {data, palette} = this.props;

    return (
      <div>
        <div className="rule" style={helpers.generic.setBackground(palette[data.color])}>
          <div className="rule__icon" onClick={this.openAssetManager}>
            <Icon className="rule__img" src={data.icon.url} alt="" />
          </div>
          <Input
            ref={this.focusInput}
            title="title"
            maxLength={limits.MAX_RULE_LENGTH}
            defaultValue={getStringFromTO(data.title)}
            placeholder="Enter rule name"
            onChange={this.onChange}
            onBlur={this.onBlur}
            onEnterPressed={this.handleEnterKey}
            onEscapePressed={this.handleEscapeKey} />
          <div className="rule__buttons">
            <div className='rule__button' onClick={this.onSave}>
              <MaterialIcon icon="done" color="#fff" />
            </div>
            <div className='rule__button' onClick={this.onCancel}>
              <MaterialIcon icon="cancel" color="#fff" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}



